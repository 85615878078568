import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ChannelsChatbot from "./Channels";
import ChatbotPersonality from "./Personality";
import FinishChatbot from "./FinishChatbotAI";
import CongratsChatbot from "./CongratsChatbot";
import QuestionsChatbot from "./Questions";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Personalidade", "Canais", "Criar"];
}

export default function CreateChatbotAI({ bots }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ChatbotPersonality handleNext={handleNext} />;
      case 1:
        return <ChannelsChatbot handleNext={handleNext} bots={bots} />;
      case 2:
        return <FinishChatbot handleNext={handleNext} />;
      // case 3:
      //   return <QuestionsChatbot handleNext={handleNext} />;
      default:
        return <CongratsChatbot />;
    }
  }

  return (
    <Paper className={classes.mainPaper}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </Paper>
  );
}
