import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useChatbots from "../../../hooks/useChatbots";

import SchoolIcon from "@material-ui/icons/School";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import {
  Avatar,
  Card,
  CardHeader,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  attr: {
    display: "flex",
    width: "100%",
    gap: 15,
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-start",
  },
  extraAttr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    marginLeft: 20,
    marginTop: 20,
  },
  cardsRoot: {
    maxWidth: 345,
  },
  avatar: {
    backgroundColor: "#fff",
  },
}));

export default function TrainningChatbot() {
  const classes = useStyles();

  const { bots, loading } = useChatbots();

  return (
    <Paper className={classes.mainPaper} key={bots.id}>
      {loading && <CircularProgress className={classes.extraAttr} />}
      {!loading && bots < 1 && "Você não posui bots treinados"}
      {bots &&
        bots.map(
          (bot) => (
            <div className={classes.extraAttr}>
              <div className={classes.attr}>
                <SchoolIcon
                  style={{
                    color: "#3f3d56",
                    fontSize: 28,
                    marginTop: -1,
                    marginRight: -9,
                  }}
                />
                <Typography variant="h6" color="secondary">
                  Treinamento concluído
                </Typography>
              </div>
              <Typography variant="body1">
                Você já pode usar o seu Chatbot
              </Typography>
              <Card className={classes.cardsRoot}>
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar}>
                      <CheckCircleOutlineIcon
                        style={{ color: green[500], fontSize: 40 }}
                      />
                    </Avatar>
                  }
                  title={`${bot.bot_name} concluído com sucesso`}
                />
              </Card>
            </div>
          )
          // bot.fine_tune_status === "succeeded" ? (
          //   <div className={classes.extraAttr}>
          //     <div className={classes.attr}>
          //       <SchoolIcon
          //         style={{
          //           color: "#3f3d56",
          //           fontSize: 28,
          //           marginTop: -1,
          //           marginRight: -9,
          //         }}
          //       />
          //       <Typography variant="h6" color="secondary">
          //         Treinamento concluído
          //       </Typography>
          //     </div>
          //     <Typography variant="body1">
          //       Você já pode usar o seu Chatbot
          //     </Typography>
          //     <Card className={classes.cardsRoot}>
          //       <CardHeader
          //         avatar={
          //           <Avatar className={classes.avatar}>
          //             <CheckCircleOutlineIcon
          //               style={{ color: green[500], fontSize: 40 }}
          //             />
          //           </Avatar>
          //         }
          //         title={`${bot.bot_name} concluído com sucesso`}
          //       />
          //     </Card>
          //   </div>
          // ) : bot.fine_tune_status === "failed" ? (
          //   <div className={classes.extraAttr}>
          //     <div className={classes.attr}>
          //       <SchoolIcon
          //         style={{
          //           color: "red",
          //           fontSize: 120,
          //           marginTop: 6,
          //         }}
          //       />
          //       <Typography className={classes.failedTextTitle}>
          //         O treinamento falhou!
          //       </Typography>
          //     </div>
          //     <Typography variant="body1">
          //       Você já pode usar o seu Chatbot
          //     </Typography>
          //     <Card className={classes.cardsRoot}>
          //       <CardHeader
          //         avatar={
          //           <Avatar className={classes.avatar}>
          //             <CheckCircleOutlineIcon
          //               style={{ color: green[500], fontSize: 40 }}
          //             />
          //           </Avatar>
          //         }
          //         title={`${bot.bot_name} falhou!`}
          //       />
          //     </Card>
          //   </div>
          // ) : (
          //   <div className={classes.extraAttr}>
          //     <div className={classes.attr}>
          //       <SchoolIcon
          //         style={{
          //           color: "#3f3d56",
          //           fontSize: 20,
          //           marginTop: 3,
          //           marginRight: -10,
          //         }}
          //       />
          //       <Typography variant="h6" color="secondary">
          //         Em treinamento
          //       </Typography>
          //     </div>
          //     <Typography variant="body1">
          //       O seu Chatbot está sendo treinado
          //     </Typography>
          //     <Card className={classes.cardsRoot}>
          //       <CardHeader
          //         avatar={
          //           <Avatar className={classes.avatar}>
          //             <CheckCircleOutlineIcon
          //               style={{ color: yellow[500], fontSize: 40 }}
          //             />
          //           </Avatar>
          //         }
          //         title={`${bot.bot_name} está em treinamento!`}
          //       />
          //     </Card>
          //   </div>
          // )
        )}
    </Paper>
  );
}
