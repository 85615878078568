import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { makeStyles, styled } from "@material-ui/core/styles";

import { TextField, Paper } from "@material-ui/core";

import logoEnvia from "../../../assets/logo_enviawhats.png";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { Field, Formik, Form } from "formik";
import { socketConnection } from "../../../services/socket";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginTop: 15,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
}));

const ChatbotPersonalitySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nome está curto")
    .max(18, "Nome está longo")
    .required("Campo obrigatório"),
});

export default function ChatbotPersonality({ handleNext }) {
  const classes = useStyles();

  const initialState = {
    name: "",
  };

  const [loading, setLoading] = useState(false);
  const [nameChatbot, setNameChatbot] = useState(initialState);

  const handleSavePersonality = async (values) => {
    localStorage.setItem("chatbotName", values.name);
    handleNext();
  };

  return (
    <Paper className={classes.mainPaper}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        Dê personalidade ao seu chatbot
      </h1>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontSize: 16,
        }}
      >
        Dê um nome e um rosto ao seu chatbot. Não se preocupe, você pode alterar
        isso mais tarde.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="https://enviawhats.com/assets/img/logo-chatbot-ia-min.png"
          alt="LogoEnvia"
          style={{ marginTop: 10, marginBottom: 10, width: "140px" }}
        />
      </div>

      <Formik
        initialValues={nameChatbot}
        enableReinitialize={true}
        validationSchema={ChatbotPersonalitySchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSavePersonality(values);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className={classes.flexForm}>
              <Field
                as={TextField}
                autoFocus
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                margin="dense"
              />
              <ButtonWithSpinner
                loading={isSubmitting}
                variant="contained"
                type="submit"
                //onClick={() => handleNextStep()}
                color="primary"
              >
                Continuar
              </ButtonWithSpinner>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
