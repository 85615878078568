import { useContext, useState } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  flexFormRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  flexButtonRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
}));

const ChatbotQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .required("Campo obrigatório")
    .min(6, "Pergunta muito curta"),
  answer: Yup.string()
    .required("Campo obrigatório")
    .min(6, "Resposta muito curta"),
});

const UserChatbotQuestionModal = ({
  open,
  onClose,
  bots,
  refresh,
  setBotOnEditing,
}) => {
  const classes = useStyles();

  const initialState = bots.conversations;

  const [contentChatbot, setContentChatbot] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setContentChatbot(initialState);
  };

  const handleSaveFinish = async (values) => {
    // console.log({
    //   whatsappId: instanceName,
    //   name: chatbotName,
    //   content: contentName,
    //   status: false,
    //   history: true,
    //   conversations: values,
    // });
    // setLoading(true);
    try {
      await api.put(`/bot/${bots.id}`, {
        content: bots.content,
        conversations: values,
      });
      toast.warning("Seu bot está em analise! Aguarde uns instantes...");
      setBotOnEditing(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
    handleClose();
    //refresh();
    //console.log(values);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...contentChatbot];
    onChangeValue[index][name] = value;
    setContentChatbot(onChangeValue);
  };

  const handleAddInput = () => {
    setContentChatbot([
      ...contentChatbot,
      {
        question: "",
        answer: "",
      },
    ]);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...contentChatbot];
    newArray.splice(index, 1);
    setContentChatbot(newArray);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Edite as suas perguntas e respostas
        </DialogTitle>
        <Formik
          initialValues={contentChatbot}
          enableReinitialize={true}
          validationSchema={ChatbotQuestionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveFinish(values);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.flexForm}>
                  {contentChatbot.map((item, index) => (
                    <div className={classes.flexFormRow} key={index}>
                      <Field
                        as={TextField}
                        name="question"
                        value={item.question}
                        placeholder="Pergunta"
                        variant="outlined"
                        margin="dense"
                        required
                        onChange={(event) => handleChange(event, index)}
                      />
                      <Field
                        as={TextField}
                        name="answer"
                        value={item.answer}
                        placeholder="Resposta"
                        variant="outlined"
                        margin="dense"
                        required
                        onChange={(event) => handleChange(event, index)}
                      />
                      {contentChatbot.length > 10 && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeleteInput(index)}
                        >
                          Deletar
                        </Button>
                      )}
                    </div>
                  ))}
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleAddInput()}
                  >
                    Adicionar
                  </Button>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  disabled={isSubmitting}
                  color="secondary"
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <ButtonWithSpinner
                  //loading={isSubmitting}
                  variant="contained"
                  type="submit"
                  //onClick={() => handleNextStep()}
                  color="primary"
                >
                  Salvar
                </ButtonWithSpinner>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserChatbotQuestionModal;
