import React from "react";

import { Avatar, Card, CardHeader, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: theme.palette.text.primary,
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
}));

export default function CardChatbot(props) {
  const { icon, title, value, loading, gpt_package, gpt_plans_counter } = props;
  const classes = useStyles();
  const resultgpt = parseInt(gpt_package) + parseInt(gpt_plans_counter);
  return !loading ? (
    <Card>
      {gpt_package ? (
        <CardHeader
          avatar={<Avatar className={classes.cardAvatar}>{icon}</Avatar>}
          title={
            <Typography
              variant="h6"
              component="h2"
              className={classes.cardTitle + 'css013'}
            >
              {title}
            </Typography>
          }
          subheader={
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.cardSubtitle}
            >
              {value} / {resultgpt}
            </Typography>
          }
        />
      ) : (
        <CardHeader
          avatar={<Avatar className={classes.cardAvatar}>{icon}</Avatar>}
          title={
            <Typography
              variant="h6"
              component="h2"
              className={classes.cardTitle}
            >
              {title}
            </Typography>
          }
          subheader={
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.cardSubtitle}
            >
              {value} / {gpt_plans_counter}
            </Typography>
          }
        />
      )}
    </Card>
  ) : (
    <Skeleton variant="rect" height={80} />
  );
}
