import { useContext, useState } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserChatbotSchema = Yup.object().shape({
  whatsappId: Yup.string().required(),
  name: Yup.string()
    .min(2, "Muito curto")
    .max(50, "Muito longo"),
  history: Yup.boolean().required(),
});

const UserChatbotEditModal = ({ open, onClose, bots, refresh }) => {
  const classes = useStyles();

  const initialState = {
    whatsappId: bots.whatsapp_id,
    name: bots.bot_name,
    history: bots.history,
  };

  const [userChatbot, setUserChatbot] = useState(initialState);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [loading, setLoading] = useState(false);


  const handleClose = () => {
    onClose();
    setUserChatbot(initialState);
  };

  const handleEditUser = async values => {
    setLoading(true);
    const chatbotData = { ...values }
    try {
      await api.put(`/bot/${bots.id}`, chatbotData);
      toast.success("Bot editado com sucesso!");
      setLoading(false);
    } catch (err) {
      toastError(err);
      console.log(err);
      setLoading(false);
    }
    handleClose();
    refresh()
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t("userChatbotModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={userChatbot}
          enableReinitialize={true}
          validationSchema={UserChatbotSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleEditUser(values);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label-instancia">{i18n.t("userChatbotModal.form.wpp")}</InputLabel>
                    <Field
                      as={Select}
                      value={userChatbot.whatsappId}
                      label={i18n.t("userChatbotModal.form.wpp")}
                      onChange={(e) =>
                        setUserChatbot({
                          ...userChatbot,
                          whatsappId: e.target.value,
                        })
                      }
                      labelId="select-label-instancia"
                      required
                    >
                      {whatsApps.map((whatsApp, index) => (
                        <MenuItem key={index + 1} value={whatsApp.id}>{whatsApp.name}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={userChatbot.history}
                        onChange={() =>
                          setUserChatbot({
                            ...userChatbot,
                            history: !userChatbot.history,
                          })
                        }
                      />
                    }
                    label="Deseja guardar historico de mensagem?"
                    labelPlacement="start"
                  />
                </div>
                {/* <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.trainning")}
                    multiline
                    rows={4}
                    placeholder="Treine o seu Assistente IA com um texto claro e objetivo (Maximo 2600 caracteres)"
                    name="train_data"
                    error={touched.train_data && Boolean(errors.train_data)}
                    helperText={touched.train_data && errors.train_data}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div> */}
                {/* <div className={classes.multFieldLine}>
                  <FormControlLabel
                    value="start"
                    control={<Switch color="primary" defaultChecked/>}
                    label="Ativar digitando..."
                    labelPlacement="start"
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Button 
                    onClick={() => {
                      setIsFullWorkDay((prevState) => !prevState);
                      setIsManuallyChangedHour(false);
                    }}
                    variant={isFullWorkDay ? "contained" : "outline"}
                    color="primary"
                    startIcon={<QueryBuilderIcon />}>                    
                    24/7
                  </Button>
                  <Button 
                    onClick={() => {
                      setIsManuallyChangedHour((prevState) => !prevState);
                      setIsFullWorkDay(false);
                    }}
                    variant={isManuallyChangedHour ? "contained" : "outline"}
                    color="primary"
                  >    
                      Escolher horário manualmente
                  </Button>                  
                </div>
                {isManuallyChangedHour && (
                  <div className={classes.extraAttr}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.run_from")}
                    autoFocus
                    name="run_from"
                    error={touched.run_from && Boolean(errors.run_from)}
                    helperText={touched.run_from && errors.run_from}
                    variant="outlined"
                    margin="dense"
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.run_to")}
                    name="run_to"
                    error={touched.run_to && Boolean(errors.run_to)}
                    helperText={touched.run_to && errors.run_to}
                    variant="outlined"
                    margin="dense"
                  />                  
                </div>                  
                )}                */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  disabled={isSubmitting}
                  color="secondary"
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                {loading ? <CircularProgress size={24} /> :
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("userModal.buttons.okEdit")}
                  </Button>
                }
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default UserChatbotEditModal;