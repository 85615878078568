import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles, styled } from "@material-ui/core/styles";
import toastError from '../../errors/toastError';
import api from '../../services/api';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WppIcon from "@material-ui/icons/WhatsApp";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button
} from "@material-ui/core";

import logo from '../../assets/enviawhats.png'


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    height: "100vh",
    width: "100vw",
    maxWidth: "none",
    overflowY: "hidden",
    backgroundColor: '#fcf5eb',
  },
  paper: {
    margin: 0,
    width: "55%",
    padding: "0 15% 0 5%",
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function generate(element) {
  return [0].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const steps = ['Instruções', 'Conexão com WhatsApp'];

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  gap: 3,
}));

export default function SignupInstructions() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hasInstance, setHasInstance] = useState(false);



  const companyName = localStorage.getItem("companyName");

  const handleCreateAccount = async () => {
    setLoading(true);
    try {
      const response = await api.post("/whatsapp", {
        name: `Instancia ${companyName}`
      });

      localStorage.setItem("whatsappId", response.data.id);
      setHasInstance(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }

  const handleNextStep = () => {
    history.push("/signup-config/conection");
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" className={`${classes.container} css-1 `}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <img src={logo} width={300} height={49} alt='Logo EnviaWhats' className={`img-logo1`} />

          <Stepper activeStep={0} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid item xs={12} md={12}>
            <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Olá, {companyName}</h1>
            <ol>
              <li>
                <p>Acesse o WhatsApp no celular que deseja conectar</p>
              </li>
              <li>
                <p>Clique em <strong>Ajustes</strong> ou <strong>Configurações</strong> e selecione <strong>Aparelhos conectados</strong></p>
              </li>
              <li>
                <p>Clique <strong>Conectar um aparelho</strong> e aponte a câmera para o código ao lado</p>
              </li>
            </ol>
            <Demo className="css-2">
              <List>
                {generate(
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <WppIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="WhatsApp"
                    />
                    <ListItemSecondaryAction>
                      {
                        hasInstance ?
                          <Button
                            variant="contained"
                            color="primary
                            "
                            size="small"
                            onClick={() => {
                              handleNextStep()
                            }}
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            Conectar Whatsapp
                          </Button>
                          :
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            size="small"
                            onClick={() => {
                              handleCreateAccount()
                            }}
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            Continuar
                          </Button>
                      }
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </Demo>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}




