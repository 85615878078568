const messages = {
	en: {
		translations: {
			signup: {
				title: "Crie uma conta e teste grátis por 3 dias nossa Plataforma de Atendimento.",
				toasts: {
					success: "Usuário criado com sucesso! Por favor faça o login!",
					fail: "Error creating user. Check the reported data."
				},
				form: {
					name: "Name",
					email: "Email",
					password: "Senha"
				},
				buttons: {
					submit: "Cadastrar",
					login: "Fazer login"
				}
			},
			login: {
				title: "Login",
				form: {
					email: "Email",
					password: "Senha"
				},
				buttons: {
					submit: "Entrar",
					register: "Ainda não tem uma conta? Cadastre-se"
				}
			},
			auth: {
				toasts: {
					success: "Login efetuado com sucesso"
				}
			},
			dashboard: {
				charts: {
					perDay: {
						title: "Atendimentos hoje: "
					}
				}
			},
			connections: {
				title: "Dispositivos",
				toasts: {
					deleted: "Dispositivo WhatsApp excluído com sucesso!"
				},
				confirmationModal: {
					deleteTitle: "Deletar",
					deleteMessage: "Tem certeza? Não pode ser revertido.",
					disconnectTitle: "Desconectar",
					disconnectMessage: "Tem certeza? Você precisará ler o QR Code novamente."
				},
				buttons: {
					add: "Adicionar WhatsApp",
					disconnect: "Desconectar",
					tryAgain: "Tente Novamente",
					qrcode: "QR CODE",
					newQr: "Novo QR CODE",
					connecting: "Conectando"
				},
				toolTips: {
					disconnected: {
						title: "Falha ao iniciar a sessão do WhatsApp",
						content: "Verifique se o seu celular está conectado à internet e tente novamente, ou solicite um novo QR Code"
					},
					qrcode: {
						title: "Aguardando a leitura do QR Code",
						content: "Clique no botão 'QR CODE' e leia o QR Code com seu celular para iniciar a sessão"
					},
					connected: {
						title: "Conexão estabelecida"
					},
					timeout: {
						title: "A conexão com o celular foi perdida",
						content: "Verifique se o seu celular está conectado à internet e o WhatsApp aberto, ou clique no botão 'Desconectar' para obter um novo QRcode"
					}
				},
				table: {
					name: "Nome",
					status: "Status",
					lastUpdate: "Última Atualização",
					default: "Padrão",
					actions: "Ações",
					session: "Sessão"
				}
			},
			whatsappModal: {
				title: {
					add: "Adicionar WhatsApp",
					edit: "Editar WhatsApp"
				},
				form: {
					name: "Nome",
					default: "Padrão"
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar"
				},
				success: "WhatsApp salvo com sucesso."
			},
			qrCode: {
				message: "Leia o QrCode para iniciar a sessão"
			},
			contacts: {
				title: "Contatos",
				toasts: {
					deleted: "Contato excluído com sucesso!"
				},
				searchPlaceholder: "Pesquisar...",
				confirmationModal: {
					deleteTitle: "Deletar",
					importTitlte: "Importar contatos",
					deleteMessage: "Tem certeza de que deseja excluir este contato? Todos os tickets relacionados serão perdidos.",
					importMessage: "Deseja importar todos os contatos do telefone?"
				},
				buttons: {
					import: "Importar Contatos",
					add: "Adicionar Contato"
				},
				table: {
					name: "Nome",
					whatsapp: "WhatsApp",
					email: "Email",
					actions: "Ações"
				}
			},
			contactModal: {
				title: {
					add: "Adicionar Contato",
					edit: "Editar Contato"
				},
				form: {
					mainInfo: "Detalhes do Contato",
					extraInfo: "Informações Adicionais",
					name: "Nome",
					number: "Número Whatsapp",
					email: "Email",
					extraName: "Nome do campo",
					extraValue: "Informações adicionais"
				},
				buttons: {
					addExtraInfo: "Adicionar informações",
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar"
				},
				success: "Contato salvo com sucesso."
			},
			queueModal: {
				title: {
					add: "Adicionar Fila",
					edit: "Editar departamento"
				},
				form: {
					name: "Nome",
					color: "Cor",
					greetingMessage: "Mensagem de saudação"
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar"
				}
			},
			userModal: {
				title: {
					add: "Adicionar usuário",
					edit: "Editar usuário"
				},
				form: {
					name: "Nome",
					email: "Email",
					password: "Senha",
					profile: "Perfil"
				},
				buttons: {
					okAdd: "Adicionar",
					okEdit: "Salvar",
					cancel: "Cancelar"
				},
				success: "Usuário salvo com sucesso."
			},
			chat: {
				noTicketMessage: "Selecione um ticket para começar a conversar."
			},
			ticketsManager: {
				buttons: {
					newTicket: "Criar Ticket"
				}
			},
			ticketsQueueSelect: {
				placeholder: "Filtrar"
			},
			tickets: {
				toasts: {
					deleted: "O ticket em que você estava foi excluído."
				},
				notification: {
					message: "Mensagem de"
				},
				tabs: {
					open: {
						title: "Em Aberto"
					},
					closed: {
						title: "Resolvido"
					},
					search: {
						title: "Pesquisar"
					}
				},
				search: {
					placeholder: "Pesquise tickets e mensagens."
				},
				buttons: {
					showAll: "Todos"
				}
			},
			transferTicketModal: {
				title: "Transferir Atendimento",
				fieldLabel: "Digite para procurar usuários",
				noOptions: "Nenhum usuário encontrado com este nome",
				buttons: {
					ok: "Transferir Atendimento",
					cancel: "Cancelar"
				}
			},
			ticketsList: {
				pendingHeader: "Aguardando",
				assignedHeader: "Em Atendimento",
				noTicketsTitle: "Nenhum resultado encontrado.",
				noTicketsMessage: "Nenhum ticket encontrado com este status ou termo de pesquisa.",
				buttons: {
					accept: "Aceitar"
				}
			},
			newTicketModal: {
				title: "Criar Ticket",
				fieldLabel: "Digite para procurar um contato",
				add: "Adicionar",
				buttons: {
					ok: "Salvar",
					cancel: "Cancelar"
				}
			},
			mainDrawer: {
				listItems: {
					dashboard: "Painel",
					connections: "Dispositivos",
					tickets: "Atendimentos",
					contacts: "Contatos",
					queues: "Filas e Chatbot",
					administration: "Opções",
					users: "Usuários",
					settings: "Configurações"
				},
				appBar: {
					user: {
						profile: "Perfil",
						logout: "Sair"
					}
				}
			},
			notifications: {
				noTickets: "Nenhuma notificação."
			},
			queues: {
				title: "Filas e Chatbot",
				table: {
					name: "Nome",
					color: "Cor",
					greeting: "Mensagem de saudação",
					actions: "Ações"
				},
				buttons: {
					add: "Adicionar Fila / Chatbot"
				},
				confirmationModal: {
					deleteTitle: "Deletar",
					deleteMessage: "Tem certeza? Não pode ser revertido! Os tickets nesta fila ainda existirão, mas não terão filas atribuídas."
				}
			},
			queueSelect: {
				inputLabel: "Departamentos"
			},
			users: {
				title: "Usuários",
				table: {
					name: "Nome",
					email: "Email",
					profile: "Perfil",
					actions: "Ações"
				},
				buttons: {
					add: "Adicionar usuário"
				},
				toasts: {
					deleted: "Usuário excluído com sucesso."
				},
				confirmationModal: {
					deleteTitle: "Deletar",
					deleteMessage: "Todos os dados do usuário serão perdidos. Os tickets abertos dos usuários serão movidos para a fila."
				}
			},
			settings: {
				success: "Configurações salvas com sucesso.",
				title: "Configurações",
				settings: {
					userCreation: {
						name: "Criação de usuário",
						options: {
							enabled: "Habilitada",
							disabled: "Desabilitada"
						}
					}
				}
			},
			messagesList: {
				header: {
					assignedTo: "Atendente:",
					buttons: {
						return: "Retornar",
						resolve: "Resolver",
						reopen: "Reabrir",
						accept: "Aceitar"
					}
				}
			},
			messagesInput: {
				placeholderOpen: "Digite uma mensagem",
				placeholderClosed: "Reabra ou aceite este ticket para enviar uma mensagem.",
				signMessage: "Assinar"
			},
			contactDrawer: {
				header: "Detalhes do Contato",
				buttons: {
					edit: "Editar Contato"
				},
				extraInfo: "Outra Iformação"
			},
			ticketOptionsMenu: {
				delete: "Deletar",
				transfer: "Transferir",
				confirmationModal: {
					title: "Deletar ticket #",
					titleFrom: "de contato ",
					message: "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas."
				},
				buttons: {
					delete: "Deletar",
					cancel: "Cancelar"
				}
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancelar"
				}
			},
			messageOptionsMenu: {
				delete: "Deletar",
				reply: "Responder",
				confirmationModal: {
					title: "Apagar mensagem?",
					message: "Esta ação não pode ser revertida."
				}
			},
			backendErrors: {
				ERR_NO_OTHER_WHATSAPP: "Selecione uma conexão padrão do WhatsApp.",
				ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
				ERR_WAPP_NOT_INITIALIZED: "O Whatspp está desconectado, acesse meus dispositivos e leia o QR Code.",
				ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões.",
				ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
				ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar a mídia do WhatsApp. Verifique a página de conexões.",
				ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
				ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
				ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
				ERR_OTHER_OPEN_TICKET: "Já existe um ticket aberto para este contato.",
				ERR_SESSION_EXPIRED: "Você foi desconectado, faça o login novamente.",
				ERR_USER_CREATION_DISABLED: "A criação do usuário foi desativada pelo administrador.",
				ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
				ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
				ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
				ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
				ERR_NO_TICKET_FOUND: "Nenhum ticket encontrado com este ID.",
				ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
				ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
				ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
				ERR_CREATING_TICKET: "Erro ao criar ticket no banco de dados.",
				ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhatsApp, talvez seja muito antiga.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
				ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é necessária se houver mais de uma fila."
			},
		},
	},
};

export { messages };
