import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useChatbots from "../../hooks/useChatbots";

import SubjectIcon from "@material-ui/icons/Subject";
import SchoolIcon from "@material-ui/icons/School";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import SettingsIcon from "@material-ui/icons/Settings";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import UserChatbotModal from "../../components/UserChatbotModal";
import UserChatbotEditModal from "../../components/UserChatbotEditModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import ChatbotDetails from "../../components/ChatbotDetails";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
} from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import { green, yellow } from "@material-ui/core/colors";
import CreateChatbotAI from "./components/CreateChatbotAI";
import ChannelsChatbot from "./components/Channels";
import { socketConnection } from "../../services/socket";
import TrainningChatbot from "./components/TrainningChatbot";
import ActiveChatbot from "./components/ActiveChatbot";
import EditChatbot from "./components/EditChatbot";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  chatbotContainer: {
    flex: 1,
    // backgroundColor: "#eee",
    padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatbotPapper: {
    backgroundColor: "#fff",
    display: "flex",
    height: "100%",
    borderRadius: 5,
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  attr: {
    display: "flex",
    width: "100%",
    gap: 15,
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-start",
  },
  attrIcon: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
  attrEnd: {
    display: "flex",
    gap: 15,
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-end",
  },
  extraAttr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    marginLeft: 20,
    marginTop: 20,
  },
  failedText: {
    color: "red",
  },
  warningText: {
    color: yellow[500],
    marginLeft: 20,
  },
  failedTextTitle: {
    color: "red",
    fontSize: "20px",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  tabFlex: {
    display: "flex",
    flexDirection: "row",
  },
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  listRoot: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  cardsRoot: {
    maxWidth: 345,
  },
  avatar: {
    backgroundColor: "#fff",
  },
  alertRoot: {
    width: "100%",
    marginBottom: "10px",
  },
}));

const UserChatbotChatbotAI = () => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  //const [enabled, setEnabled] = useState(false);
  //const [chatbots, dispatch] = useReducer(reducer, []);
  const [messageTab, setMessageTab] = useState("create");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);

  const { bots } = useChatbots();

  // const handleOpenUserModal = () => {
  //   setUserModalOpen(true);
  // };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleChangeTab = (e, newValue) => {
    setMessageTab(newValue);
  };

  useEffect(() => {
    //console.log("aqui condicional", bots);
    if (bots && bots.length > 0) {
      console.log("aqui", bots);
      setMessageTab("trainning");
    }
  }, [bots.length]);

  return (
    <div className={classes.chatbotContainer} onScroll={handleScroll}>
      <MainHeader>
        <Title>{i18n.t("usersChatbot.title")}</Title>
      </MainHeader>

      <div className={classes.alertRoot}>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            color="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            }
          >
            <ol style={{ color: "#fff" }}>
              <li>Dê um nome para o seu Chatbot</li>
              <li>
                Adicione o seu texto de treinamento com no mínimo 1000 palavras,
                quanto mais informações da sua empresa e serviços, melhor será
                as respostas do ChatGPT
              </li>
              <li>
                Ative o seu chatbot para trabalhar em suas Filas ou
                Departamentos
              </li>
            </ol>
          </Alert>
        </Collapse>
      </div>

      <div className={classes.chatbotPapper}>
        <Grid container spacing={0}>
          <Grid xs={2} className={classes.contactsWrapper}>
            <Tabs
              orientation="vertical"
              value={messageTab}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
            >
              <Tab
                label="Adicionar conhecimento"
                icon={<SubjectIcon />}
                value="create"
              />
              <Divider variant="middle" />
              <Tab
                label="Treinamento"
                icon={<SchoolIcon />}
                value="trainning"
              />
              <Divider variant="middle" />
              <Tab
                label="Ative seu Chatbot"
                icon={<ToggleOnIcon />}
                value="active"
              />
              <Divider variant="middle" />
              <Tab label="Editar" icon={<SettingsIcon />} value="edit" />
              <Divider variant="middle" />
            </Tabs>
          </Grid>
          <Grid xs={10} className={classes.messagessWrapper}>
            <TabPanel
              value={messageTab}
              name="create"
              className={classes.ticketsWrapper}
            >
              <CreateChatbotAI bots={bots} />
            </TabPanel>
            <TabPanel
              value={messageTab}
              name="trainning"
              className={classes.ticketsWrapper}
            >
              <TrainningChatbot />
            </TabPanel>
            <TabPanel
              value={messageTab}
              name="active"
              className={classes.ticketsWrapper}
            >
              <ActiveChatbot />
            </TabPanel>
            <TabPanel
              value={messageTab}
              name="edit"
              className={classes.ticketsWrapper}
            >
              <EditChatbot />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserChatbotChatbotAI;
