import { useState, useEffect, useCallback } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
//import { toast } from "react-toastify";

const useChatbots = () => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  //const [botHasChange, setBotHasChange] = useState(false);
  const [bots, setBots] = useState([]);

  const fetchBots = async () => {
    try {
      const { data } = await api.get("/bot", {});

      // for (let bot of bots) {
      //   for (let botData of data.data) {
      //     if (bot.id === botData.id) {
      //       console.log("achou bot");
      //       if (
      //         bot.fine_tune_status === "running" &&
      //         botData.fine_tune_status === "succeeded"
      //       ) {
      //         console.log("mudou status");
      //         toast.success(`${botData.bot_name} foi treinado com sucesso`);
      //         //setBotHasChange(true);
      //       }
      //       continue;
      //     }
      //   }
      // }

      //console.log("console data", data.data);
      setBots(data.data);

      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      console.log("erro bot", err);
      toastError(err);
    }
  };

  useEffect(() => {
    fetchBots();
    // const delayDebounceFn = setInterval(fetchBots, 10000);

    // return () => clearInterval(delayDebounceFn);
  }, []);

  return { bots, loading, hasMore, fetchBots };
};

export default useChatbots;
