import { useState } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import { useContext } from "react";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserChatbotSchema = Yup.object().shape({
  whatsappId: Yup.string().required(),
  name: Yup.string()
    .min(2, "Muito curto")
    .max(50, "Muito longo")
    .required("Campo obrigatório"),
  content: Yup.string().required("Campo obrigatório").max(4000),
});

const UserChatbotModal = ({ open, onClose, refresh, bots }) => {
  const classes = useStyles();

  const initialState = {
    whatsappId: "",
    name: "",
    content: "",
    status: false,
  };

  const [userChatbot, setUserChatbot] = useState(initialState);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  function filtrarObjetosPorPropriedades(
    instacia1,
    instancia2,
    propriedadeInstancia1,
    propriedadeInstancia2
  ) {
    // Filtrar os objetos do array2 com base nas propriedades que não estão presentes no array1
    var objetosFiltrados = instancia2.filter(function (objeto) {
      // Converter o valor da propriedade para string e verificar se não está presente no array1
      return (
        objeto.hasOwnProperty(propriedadeInstancia2) &&
        !instacia1.some(
          (item) =>
            String(item[propriedadeInstancia1]) ===
            String(objeto[propriedadeInstancia2])
        )
      );
    });

    return objetosFiltrados;
  }

  var objetosFiltrados = filtrarObjetosPorPropriedades(
    bots,
    whatsApps,
    "whatsapp_id",
    "id"
  );

  const handleClose = () => {
    onClose();
    setUserChatbot(initialState);
    console.log(objetosFiltrados);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSaveUser = async (values) => {
    setLoading(true);
    const chatbotData = { ...values };
    try {
      await api.post("/bot", {
        ...chatbotData,
        history: checked,
      });

      toast.success("Bot criado com sucesso!");
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
    handleClose();
    refresh();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">
          {i18n.t("userChatbotModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={userChatbot}
          enableReinitialize={true}
          validationSchema={UserChatbotSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label-instancia">
                      {i18n.t("userChatbotModal.form.wpp")}
                    </InputLabel>
                    <Field
                      as={Select}
                      value={userChatbot.whatsappId}
                      label={i18n.t("userChatbotModal.form.wpp")}
                      onChange={(e) =>
                        setUserChatbot({
                          ...userChatbot,
                          whatsappId: e.target.value,
                        })
                      }
                      labelId="select-label-instancia"
                      required
                    >
                      {objetosFiltrados.map((whatsApp, index) => (
                        <MenuItem key={index + 1} value={whatsApp.id}>
                          {whatsApp.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <Typography variant="caption">
                    Se não aparecer instância, crie uma nova em{" "}
                    <strong>Dispositivos</strong>
                  </Typography>
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.trainning")}
                    multiline
                    rows={20}
                    placeholder="Treine o seu Assistente IA com um texto claro e objetivo"
                    name="content"
                    error={touched.content && Boolean(errors.content)}
                    helperText={touched.content && errors.content}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Deseja guardar historico de mensagem?"
                    labelPlacement="start"
                  />
                </div>
                {/* <div className={classes.multFieldLine}>
                  <FormControlLabel
                    value="start"
                    control={<Switch color="primary" defaultChecked/>}
                    label="Ativar digitando..."
                    labelPlacement="start"
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Button 
                    onClick={() => {
                      setIsFullWorkDay((prevState) => !prevState);
                      setIsManuallyChangedHour(false);
                    }}
                    variant={isFullWorkDay ? "contained" : "outline"}
                    color="primary"
                    startIcon={<QueryBuilderIcon />}>                    
                    24/7
                  </Button>
                  <Button 
                    onClick={() => {
                      setIsManuallyChangedHour((prevState) => !prevState);
                      setIsFullWorkDay(false);
                    }}
                    variant={isManuallyChangedHour ? "contained" : "outline"}
                    color="primary"
                  >    
                      Escolher horário manualmente
                  </Button>                  
                </div>
                {isManuallyChangedHour && (
                  <div className={classes.extraAttr}>
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.run_from")}
                    autoFocus
                    name="run_from"
                    error={touched.run_from && Boolean(errors.run_from)}
                    helperText={touched.run_from && errors.run_from}
                    variant="outlined"
                    margin="dense"
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("userChatbotModal.form.run_to")}
                    name="run_to"
                    error={touched.run_to && Boolean(errors.run_to)}
                    helperText={touched.run_to && errors.run_to}
                    variant="outlined"
                    margin="dense"
                  />                  
                </div>                  
                )}                */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>

                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {i18n.t("userModal.buttons.okAdd")}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserChatbotModal;
