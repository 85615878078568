import * as React from 'react';
import { useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Container,
  Paper,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  CircularProgress
} from "@material-ui/core";

import logo from '../../assets/enviawhats.png'
import toastError from '../../errors/toastError';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    height: "100vh",
    width: "100vw",
    maxWidth: "none",
    overflowY: "hidden",
    backgroundColor: '#79D79D'
  },
  paper: {
    margin: 0,
    width: "55%",
    padding: "0 15% 0 5%",
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  qrCode: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 5
  }
}));

const steps = ['Instruções', 'Conexão com WhatsApp'];

export default function SignupConnectWpp() {
  const history = useHistory();
  const [qrCode, setQrCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const whatsAppId = localStorage.getItem("whatsappId");

  const classes = useStyles();

  React.useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  React.useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      console.log(data);
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        // console.log('done');
      }

      if (data.action === "update" && data.session.status === "CONNECTED") {
        // console.log('conectado');
        history.push("/tickets");
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" className={`${classes.container} css-1 `}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <img src={logo} width={300} height={49} alt='Logo EnviaWhats' className={`img-logo1`} />

          <Stepper activeStep={1} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} >
              <Typography color="primary" variant="h6" gutterBottom sx={{ mt: 2 }}>
                {i18n.t("qrCode.message")}
              </Typography>
              {qrCode ? (
                <QRCode className='css-3' value={qrCode} size={256}/>
              ) : (
                <div className={classes.qrCode}>
                <Typography>Aguarde o seu QrCode ser gerado</Typography>
                {loading ? <CircularProgress /> : ''}
                </div>
              )}            
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}