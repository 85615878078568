import React, { useState } from "react";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import { TextField, Paper } from "@material-ui/core";

import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { Field, Formik, Form } from "formik";
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";
import { toast } from "react-toastify";
import toastError from "../../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginTop: 15,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
}));

const ChatbotFinishSchema = Yup.object().shape({
  content: Yup.string()
    .required("Campo obrigatório")
    .min(1000, "Seu texto deve conter no mínimo 1000 palavras"),
});

export default function FinishChatbot({ handleNext }) {
  const classes = useStyles();

  const initialState = {
    content: "",
  };

  const [loading, setLoading] = useState(false);
  const [contentChatbot, setContentChatbot] = useState(initialState);
  const chatbotName = localStorage.getItem("chatbotName");
  const instanceName = localStorage.getItem("instanceName");

  const handleSaveFinish = async (values) => {
    try {
      await api.post("/bot", {
        whatsappId: instanceName,
        name: chatbotName,
        content: values.content,
        status: false,
        history: true,
      });

      handleNext();

      toast.success(
        "Seu treinamento foi aceito! Ative o seu chatbot para começar a usá-lo."
      );
    } catch (err) {
      toastError(err);
    }
    // localStorage.setItem("contentName", values.content);
    // handleNext();
  };

  return (
    <Paper className={classes.mainPaper}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        Dê personalidade ao seu chatbot
      </h1>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontSize: 16,
        }}
      >
        Dê um nome e um rosto ao seu chatbot. Não se preocupe, você pode alterar
        isso mais tarde.
      </p>

      <Formik
        initialValues={contentChatbot}
        enableReinitialize={true}
        validationSchema={ChatbotFinishSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveFinish(values);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className={classes.flexForm}>
              <Field
                as={TextField}
                label={i18n.t("userChatbotModal.form.trainning")}
                style={{ width: "70%" }}
                multiline
                rows={20}
                placeholder="Treine o seu Assistente IA com um texto claro e objetivo"
                name="content"
                error={touched.content && Boolean(errors.content)}
                helperText={touched.content && errors.content}
                variant="outlined"
                margin="dense"
              />
              <ButtonWithSpinner
                loading={isSubmitting}
                variant="contained"
                type="submit"
                //onClick={() => handleNextStep()}
                color="primary"
              >
                Salvar
              </ButtonWithSpinner>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
