import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Link as RouterLink, useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import {
  Badge,
  Button,
  Collapse,
  List,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AndroidIcon from "@material-ui/icons/Android";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import PaymentIcon from "@material-ui/icons/Payment";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import { MdOutlineViewKanban } from "react-icons/md";
import moment from "moment";
import {
  AllInbox,
  Assessment,
  Assignment,
  AssignmentInd,
  ContactSupport,
  ContactSupportOutlined,
  Dashboard,
  Devices,
  Schedule,
  Send,
  SupervisorAccount,
} from "@material-ui/icons";

function ListItemLink(props) {
  const { icon, primary, to, className, tooltip, title } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      {tooltip ? (
        <Tooltip title={title} placement="top">
          <ListItem button component={renderLink} className={className}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem button component={renderLink} className={className}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      )}
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const { drawerClose, drawerOpen } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const history = useHistory();

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };
  const email = localStorage.getItem("email");
  const plano = localStorage.getItem("PlanId");
  const company = localStorage.getItem("companyId");

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = new Date(`${year}-${month}-${day}`);
  var datav = localStorage.getItem("companyDueDate");
  //datav = datav.format("DD/MM/yyyy")
  const dataString = datav;
  const formatoEntrada = "DD/MM/YYYY";
  const formatoSaida = "YYYY-MM-DD";
  const datahObj = moment(formattedDate, formatoEntrada);
  const dataObj = moment(dataString, formatoEntrada);
  const datahoje = datahObj.format(formatoSaida);
  const dataFormatadav = dataObj.format(formatoSaida);

  const valido = datahoje < dataFormatadav;
  console.log(valido);
  console.log(datahoje + dataFormatadav);

  const handleLoginCampaign = async (email, destino) => {
    // let data = JSON.stringify({
    //   email: "vitor.ulhoa@hotmail.com",
    // });
    // let config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: "https://enviawhats.com/api/generate-login-link",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "User-Agent": "insomnia/2023.5.8",
    //   },
    //   data: data,
    // };
    // axios
    //   .request(config)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // const response = await api.post(
    //   "https://enviawhats.com/api/generate-login-link",
    //   {
    //     email: "vitor.ulhoa@hotmail.com",
    //   }
    // );
    // console.log("response", response.data);
    // window.open(`${response.data}`, "_blank");
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/disparo/login`,
        {
          email: email,
          destino: destino,
        }
      );
      window.open(response.data, "_self");
      console.log("response", response);
    } catch (error) {
      console.log(error);
      toastError("Usuário não encontrado");
    }
  };

  return (
    <div onClick={drawerClose}>
      {valido === false && (
        <ListItemLink
          to="/subscription"
          primary="Upgrade de plano"
          title="Upgrade de plano"
          tooltip
          icon={<PaymentIcon />}
          //className={classes.menuItem}
        />
      )}

      {drawerOpen === false ? (
        <>
          {valido && (
            <>
              <List component="div" disablePadding>
                <ListItemLink
                  to="/subscription"
                  primary="Upgrade de plano"
                  title="Upgrade de plano"
                  tooltip
                  icon={<PaymentIcon />}
                  //className={classes.menuItem}
                />
                <ListItemLink
                  to="/creditsgpt"
                  primary="Creditos ChatGPT"
                  title="Creditos ChatGPT"
                  tooltip
                  icon={<PaymentIcon />}
                  //className={classes.menuItem}
                />
              </List>

              <Can
                role={user.profile}
                perform="dashboard:view"
                yes={() => (
                  <ListItemLink
                    to="/"
                    title="Estatisticas"
                    primary="Estatisticas"
                    tooltip
                    icon={<DashboardOutlinedIcon />}
                  />
                )}
              />
              <ListItemLink
                to="/connections"
                tooltip
                title={i18n.t("mainDrawer.listItems.connections")}
                primary={i18n.t("mainDrawer.listItems.connections")}
                icon={
                  <Badge
                    badgeContent={connectionWarning ? "!" : 0}
                    color="error"
                  >
                    <SyncAltIcon />
                  </Badge>
                }
              />
              <ListItemLink
                to="/queues"
                title={i18n.t("mainDrawer.listItems.queues")}
                tooltip
                primary={i18n.t("mainDrawer.listItems.queues")}
                icon={<AccountTreeOutlinedIcon />}
              />
              <ListItemLink
                to="/tickets"
                tooltip
                title={i18n.t("mainDrawer.listItems.tickets")}
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
              />

              <ListItemLink
                to="/Kanban"
                tooltip
                title={i18n.t("mainDrawer.listItems.ControlledBoard")}
                primary={i18n.t("mainDrawer.listItems.ControlledBoard")}
                icon={<MdOutlineViewKanban />}
              />

              <ListItemLink
                to="/userschatbot"
                tooltip
                title={i18n.t("mainDrawer.listItems.usersChatbot")}
                primary={i18n.t("mainDrawer.listItems.usersChatbot")}
                icon={<AndroidIcon />}
              />
              <ListItemLink
                to="/chatbot"
                tooltip
                title={i18n.t("mainDrawer.listItems.chatbot")}
                primary={i18n.t("mainDrawer.listItems.chatbot")}
                icon={<ChatBubbleIcon />}
              />
              <ListItemLink
                to="/todolist"
                tooltip
                title={i18n.t("mainDrawer.listItems.todolist")}
                primary={i18n.t("mainDrawer.listItems.todolist")}
                icon={<EventIcon />}
              />

              <ListItemLink
                to="/quick-messages"
                tooltip
                title={i18n.t("mainDrawer.listItems.quickMessages")}
                primary={i18n.t("mainDrawer.listItems.quickMessages")}
                icon={<FlashOnIcon />}
              />

              <ListItemLink
                to="/contacts"
                tooltip
                title={i18n.t("mainDrawer.listItems.contacts")}
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
              />

              <ListItemLink
                to="/schedules"
                tooltip
                title={i18n.t("mainDrawer.listItems.schedules")}
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventIcon />}
              />

              <ListItemLink
                to="/tags"
                tooltip
                title={i18n.t("mainDrawer.listItems.tags")}
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferIcon />}
              />

              <ListItemLink
                to="/users"
                tooltip
                title={i18n.t("mainDrawer.listItems.users")}
                primary={i18n.t("mainDrawer.listItems.users")}
                icon={<PeopleAltOutlinedIcon />}
              />

              <ListItemLink
                to="/chats"
                tooltip
                title={i18n.t("mainDrawer.listItems.chats")}
                primary={i18n.t("mainDrawer.listItems.chats")}
                icon={
                  <Badge color="secondary" variant="dot" invisible={invisible}>
                    <ForumIcon />
                  </Badge>
                }
              />

              <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                  <>
                    <Tooltip title="Painel">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/dashboard")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Dashboard />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Painel
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Meus dispositivos">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/device")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Devices />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Meus dispositivos
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Modelos de mensagem">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/template")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Assignment />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Modelos de mensagem
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Importar contatos">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/contact")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <AssignmentInd />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Importar contatos
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Enviar mensagem">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/sent-text-message")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Send />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Enviar mensagem
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Enviar em massa">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/bulk-message")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <AllInbox />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Enviar em massa
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Agendar mensagem">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/schedule-message")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Schedule />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Agendar mensagem
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Relatórios de entrega">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() => handleLoginCampaign(email, "/user/logs")}
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <Assessment />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Relatórios de entrega
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>
                    <Tooltip title="API">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() => handleLoginCampaign(email, "/user/apps")}
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <CodeRoundedIcon />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            API
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>

                    {/* <Divider /> */}
                    {/* {showCampaigns && (
            <>
              <ListItem
                button
                onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
              >
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.campaigns")}
                />
                {openCampaignSubmenu ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                style={{ paddingLeft: 15 }}
                in={openCampaignSubmenu}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem onClick={() => history.push("/campaigns")} button>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Listagem" />
                  </ListItem>
                  <ListItem
                    onClick={() => history.push("/contact-lists")}
                    button
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Listas de Contatos" />
                  </ListItem>
                  <ListItem
                    onClick={() => history.push("/campaigns-config")}
                    button
                  >
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurações" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )} */}
                    {user.super && (
                      <ListItemLink
                        to="/announcements"
                        tooltip
                        title={i18n.t("mainDrawer.listItems.annoucements")}
                        primary={i18n.t("mainDrawer.listItems.annoucements")}
                        icon={<AnnouncementIcon />}
                      />
                    )}

                    <ListItemLink
                      to="/messages-api"
                      tooltip
                      title={i18n.t("mainDrawer.listItems.messagesAPI")}
                      primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                      icon={<CodeRoundedIcon />}
                    />
                    {/* <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<LocalAtmIcon />}
            /> */}

                    <ListItemLink
                      to="/settings"
                      tooltip
                      title={i18n.t("mainDrawer.listItems.settings")}
                      primary={i18n.t("mainDrawer.listItems.settings")}
                      icon={<SettingsOutlinedIcon />}
                    />
                    <a
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      href="https://ajuda.enviawhats.com"
                    >
                      <div class="MuiListItemIcon-root">
                        <svg
                          class="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                        </svg>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Ajuda
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </a>
                    <Tooltip title="Suporte">
                      <Button
                        class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                        tabindex="0"
                        role="button"
                        aria-disabled="false"
                        onClick={() =>
                          handleLoginCampaign(email, "/user/support")
                        }
                      >
                        <div class="MuiListItemIcon-root">
                          <ListItemIcon>
                            <SupervisorAccount />
                          </ListItemIcon>
                        </div>
                        <div class="MuiListItemText-root">
                          <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                            Suporte
                          </span>
                        </div>
                        <span class="MuiTouchRipple-root"></span>
                      </Button>
                    </Tooltip>

                    {/*             <ListItemLink
            to="/subscription"
            primary="Assinatura"
            icon={<PaymentIcon />}
            //className={classes.menuItem}
          /> */}
                  </>
                )}
              />
            </>
          )}
        </>
      ) : (
        <>
          {valido && (
            <>
              <List>
                <ListSubheader
                  className="MenusExtras"
                  style={{ color: "#8f9bba !important" }}
                  inset
                >
                  Assinatura
                </ListSubheader>
              </List>
              <List component="div" disablePadding>
                <ListItemLink
                  to="/subscription"
                  primary="Upgrade de plano"
                  title="Upgrade de plano"
                  tooltip
                  icon={<PaymentIcon />}
                  //className={classes.menuItem}
                />
                <ListItemLink
                  to="/creditsgpt"
                  primary="Creditos ChatGPT"
                  title="Creditos ChatGPT"
                  tooltip
                  icon={<PaymentIcon />}
                  //className={classes.menuItem}
                />
              </List>
              <List>
                <ListSubheader
                  className="MenusExtras"
                  style={{ color: "#8f9bba !important" }}
                  inset
                >
                  Plataforma de atendimento
                </ListSubheader>
              </List>
              <Can
                role={user.profile}
                perform="dashboard:view"
                yes={() => (
                  <ListItemLink
                    to="/"
                    primary="Estatísticas"
                    icon={<DashboardOutlinedIcon />}
                  />
                )}
              />
              <ListItemLink
                to="/connections"
                primary={i18n.t("mainDrawer.listItems.connections")}
                icon={
                  <Badge
                    badgeContent={connectionWarning ? "!" : 0}
                    color="error"
                  >
                    <SyncAltIcon />
                  </Badge>
                }
              />
              <ListItemLink
                to="/queues"
                primary={i18n.t("mainDrawer.listItems.queues")}
                icon={<AccountTreeOutlinedIcon />}
              />
              <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
              />

              <ListItemLink
                to="/Kanban"
                primary={i18n.t("mainDrawer.listItems.ControlledBoard")}
                icon={<MdOutlineViewKanban />}
              />

              <ListItemLink
                to="/userschatbot"
                primary={i18n.t("mainDrawer.listItems.usersChatbot")}
                icon={<AndroidIcon />}
              />
              <ListItemLink
                to="/chatbot"
                primary={i18n.t("mainDrawer.listItems.chatbot")}
                icon={<ChatBubbleIcon />}
              />

              <ListItemLink
                to="/todolist"
                primary={i18n.t("mainDrawer.listItems.todolist")}
                icon={<EventIcon />}
              />

              <ListItemLink
                to="/quick-messages"
                primary={i18n.t("mainDrawer.listItems.quickMessages")}
                icon={<FlashOnIcon />}
              />

              <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
              />

              <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventIcon />}
              />

              <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferIcon />}
              />

              <ListItemLink
                to="/users"
                primary={i18n.t("mainDrawer.listItems.users")}
                icon={<PeopleAltOutlinedIcon />}
              />

              <ListItemLink
                to="/chats"
                primary={i18n.t("mainDrawer.listItems.chats")}
                icon={
                  <Badge color="secondary" variant="dot" invisible={invisible}>
                    <ForumIcon />
                  </Badge>
                }
              />

              <ListItemLink
                to="/settings"
                primary={i18n.t("mainDrawer.listItems.settings")}
                icon={<SettingsOutlinedIcon/>}
              />

              <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                  <>
                    <List>
                      <ListSubheader
                        className="MenusExtras"
                        style={{ color: "#8f9bba !important" }}
                        inset
                      >
                        Disparo em massa
                      </ListSubheader>
                    </List>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/dashboard")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Dashboard />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Estatísticas
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() => handleLoginCampaign(email, "/user/device")}
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Devices />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Meus dispositivos
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/template")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Assignment />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Modelos de mensagem
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/contact")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <AssignmentInd />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Importar contatos
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/sent-text-message")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Send />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Enviar mensagem
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/bulk-message")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <AllInbox />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Enviar em massa
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() =>
                        handleLoginCampaign(email, "/user/schedule-message")
                      }
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Schedule />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Agendar mensagem
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() => handleLoginCampaign(email, "/user/logs")}
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <Assessment />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Relatórios de entrega
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>
                    <Button
                      class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      tabindex="0"
                      role="button"
                      aria-disabled="false"
                      onClick={() => handleLoginCampaign(email, "/user/apps")}
                    >
                      <div class="MuiListItemIcon-root">
                        <ListItemIcon>
                          <CodeRoundedIcon />
                        </ListItemIcon>
                      </div>
                      <div class="MuiListItemText-root">
                        <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                          Integrações API
                        </span>
                      </div>
                      <span class="MuiTouchRipple-root"></span>
                    </Button>

                    {/* <Divider /> 
                    <List>
                      <ListSubheader
                        className="MenusExtras"
                        style={{ color: "#8f9bba !important" }}
                        inset
                      >
                        {i18n.t("mainDrawer.listItems.administration")}
                      </ListSubheader>
                    </List>*/}
                    {/* {showCampaigns && (
            <>
              <ListItem
                button
                onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
              >
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.campaigns")}
                />
                {openCampaignSubmenu ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                style={{ paddingLeft: 15 }}
                in={openCampaignSubmenu}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem onClick={() => history.push("/campaigns")} button>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Listagem" />
                  </ListItem>
                  <ListItem
                    onClick={() => history.push("/contact-lists")}
                    button
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Listas de Contatos" />
                  </ListItem>
                  <ListItem
                    onClick={() => history.push("/campaigns-config")}
                    button
                  >
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurações" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )} }
                    {user.super && (
                      <ListItemLink
                        to="/announcements"
                        primary={i18n.t("mainDrawer.listItems.annoucements")}
                        icon={<AnnouncementIcon />}
                      />
                    )}

                    {/* <ListItemLink
                      to="/messages-api"
                      primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                      icon={<CodeRoundedIcon />}
                    /> */}
                    {/* <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<LocalAtmIcon />}
            /> 
                    <>
                      <List component="div" disablePadding>
                        <ListItemLink
                          to="/subscription"
                          primary="Upgrade de plano"
                          icon={<PaymentIcon />}
                          //className={classes.menuItem}
                        />
                        <ListItemLink
                          to="/creditsgpt"
                          primary="Creditos ChatGPT"
                          icon={<PaymentIcon />}
                          //className={classes.menuItem}
                        />
                      </List>
                    </>
                    <ListItemLink
                      to="/settings"
                      primary={i18n.t("mainDrawer.listItems.settings")}
                      icon={<SettingsOutlinedIcon />}
                    />*/}
                  </>
                )}
              />
              <List>
                <ListSubheader
                  className="MenusExtras"
                  style={{ color: "#8f9bba !important" }}
                  inset
                >
                  Suporte
                </ListSubheader>
              </List>
              <a
                class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                tabindex="0"
                role="button"
                aria-disabled="false"
                href="https://ajuda.enviawhats.com"
              >
                <div class="MuiListItemIcon-root">
                  <svg
                    class="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                  </svg>
                </div>
                <div class="MuiListItemText-root">
                  <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Ajuda
                  </span>
                </div>
                <span class="MuiTouchRipple-root"></span>
              </a>
              <Button
                class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                tabindex="0"
                role="button"
                aria-disabled="false"
                onClick={() => handleLoginCampaign(email, "/user/support")}
              >
                <div class="MuiListItemIcon-root">
                  <ListItemIcon>
                    <SupervisorAccount />
                  </ListItemIcon>
                </div>
                <div class="MuiListItemText-root">
                  <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                    Suporte
                  </span>
                </div>
                <span class="MuiTouchRipple-root"></span>
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MainListItems;
