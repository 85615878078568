import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useChatbots from "../../../hooks/useChatbots";

import {
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
} from "@material-ui/core";
import api from "../../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  listRoot: {
    width: "100%",
    //maxWidth: 360,
    //backgroundColor: theme.palette.background.paper,
  },
  extraAttr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    marginLeft: 20,
    marginTop: 20,
  },
}));

export default function ActiveChatbot() {
  const classes = useStyles();

  const { fetchBots, bots, loading } = useChatbots();

  const toggleBotStatus = async (botId, currentStatus) => {
    await api.put(`/bot/${botId}`, {
      status: !currentStatus,
    });
    toast.success("Status alterado com sucesso!");

    fetchBots();
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     fetchBots();
  //   }, 500);
  //   return () => clearTimeout(delayDebounceFn);
  // }, [fetchBots]);

  return (
    <Paper className={classes.mainPaper}>
      <List className={classes.listRoot} key={bots.id}>
        {loading && <CircularProgress className={classes.extraAttr} />}
        {!loading && bots < 1 && "Você não posui bots treinados"}
        {bots &&
          bots.map(
            (bot) => (
              <ListItem key={bot.id}>
                <ListItemIcon>
                  {bot.status ? (
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        borderRadius: "50%",
                        backgroundColor: "#2ecc71",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        borderRadius: "50%",
                        backgroundColor: "#f39c12",
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-active"
                  primary={bot.bot_name}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={bot.status}
                      onChange={() => {
                        toggleBotStatus(bot.id, bot.status);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={bot.status ? "Ligado" : "Desligado"}
                  labelPlacement="start"
                />
              </ListItem>
            )
            // bot.fine_tune_status === "succeeded" ? (
            //   <ListItem key={bot.id}>
            //     <ListItemIcon>
            //       {bot.status ? (
            //         <div
            //           style={{
            //             width: 15,
            //             height: 15,
            //             borderRadius: "50%",
            //             backgroundColor: "#2ecc71",
            //           }}
            //         />
            //       ) : (
            //         <div
            //           style={{
            //             width: 15,
            //             height: 15,
            //             borderRadius: "50%",
            //             backgroundColor: "#f39c12",
            //           }}
            //         />
            //       )}
            //     </ListItemIcon>
            //     <ListItemText
            //       id="switch-list-label-active"
            //       primary={bot.bot_name}
            //     />
            //     <FormControlLabel
            //       control={
            //         <Switch
            //           color="primary"
            //           checked={bot.status}
            //           onChange={() => {
            //             toggleBotStatus(bot.id, bot.status);
            //           }}
            //           inputProps={{ "aria-label": "controlled" }}
            //         />
            //       }
            //       label={bot.status ? "Ligado" : "Desligado"}
            //       labelPlacement="start"
            //     />
            //   </ListItem>
            // ) : (
            //   <ListItem key={bot.id}>
            //     <ListItemText
            //       id="switch-list-label-active"
            //       primary={`${bot.bot_name} não pode ser ativo, pois, está em treinamento`}
            //     />
            //   </ListItem>
            // )
          )}
      </List>
    </Paper>
  );
}
