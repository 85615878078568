import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		padding: theme.spacing(4),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
	},

	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
	},
	messagesWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: "#eee",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	},
}));

const TicketsCustom = () => {
	const classes = useStyles();
	const { ticketId } = useParams();

	return (
		<div className={classes.chatContainer + ' css70'}>
			<div className={classes.chatPapper + ' css71'}>
				<Grid container spacing={0}>
					<Grid item xs={4} className={classes.contactsWrapper + ' css72'}>
						<TicketsManager />
					</Grid>
					<Grid item xs={8} className={classes.messagesWrapper + ' css73'}>
						{ticketId ? (
							<>
								<Ticket />
							</>
						) : (
							<Paper square variant="outlined" className={`${classes.welcomeMsg} css74`} style={{ textAlign: 'center' }}>
								<div>
									<img src="https://enviawhats.com/assets/img/atendimento-enviawhats.svg" alt="Atendimento EnviaWhats" style={{ width: '65%' }} />
									<p style={{ display: 'block' }}>Clique nas conversaas para iniciar o atendimento dos clientes.</p>
								</div>
							</Paper>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default TicketsCustom;
