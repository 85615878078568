import React, { useContext, useState } from "react";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles, styled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WppIcon from "@material-ui/icons/WhatsApp";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";

import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import logoEnvia from "../../../assets/logo_enviawhats.png";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { Field, Formik, Form } from "formik";
import { i18n } from "../../../translate/i18n";
import { WhatsAppsContext } from "../../../context/WhatsApp/WhatsAppsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginTop: 15,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  multFieldLine: {
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

const ChatbotChannelSchema = Yup.object().shape({
  whatsappId: Yup.string().required(),
});

export default function ChannelsChatbot({ handleNext, bots }) {
  const classes = useStyles();

  const initialState = {
    whatsappId: "",
  };

  const [instanceChatbot, setInstanceChatbot] = useState(initialState);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [loading, setLoading] = useState(false);

  function filtrarObjetosPorPropriedades(
    instacia1,
    instancia2,
    propriedadeInstancia1,
    propriedadeInstancia2
  ) {
    // Filtrar os objetos do array2 com base nas propriedades que não estão presentes no array1
    var objetosFiltrados = instancia2.filter(function (objeto) {
      // Converter o valor da propriedade para string e verificar se não está presente no array1
      return (
        objeto.hasOwnProperty(propriedadeInstancia2) &&
        !instacia1.some(
          (item) =>
            String(item[propriedadeInstancia1]) ===
            String(objeto[propriedadeInstancia2])
        )
      );
    });

    return objetosFiltrados;
  }

  var objetosFiltrados = filtrarObjetosPorPropriedades(
    bots,
    whatsApps,
    "whatsapp_id",
    "id"
  );

  const handleSaveChannel = async (values) => {
    console.log(instanceChatbot.whatsappId);
    localStorage.setItem("instanceName", instanceChatbot.whatsappId);
    handleNext();
  };

  return (
    <Paper className={classes.mainPaper}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        Onde você quer inserir o seu chatbot?
      </h1>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontSize: 16,
        }}
      >
        Selecione em quais canais seu chatbot estará ativo quando for publicado
      </p>
      <Formik
        initialValues={instanceChatbot}
        enableReinitialize={true}
        validationSchema={ChatbotChannelSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveChannel(values);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className={classes.flexForm}>
              <FormControl style={{ width: "70%" }}>
                <InputLabel id="select-label-instancia">
                  {i18n.t("userChatbotModal.form.wpp")}
                </InputLabel>
                <Field
                  as={Select}
                  value={instanceChatbot.whatsappId}
                  label={i18n.t("userChatbotModal.form.wpp")}
                  onChange={(e) =>
                    setInstanceChatbot({
                      ...instanceChatbot,
                      whatsappId: e.target.value,
                    })
                  }
                  labelId="select-label-instancia"
                  required
                >
                  {objetosFiltrados.map((whatsApp, index) => (
                    <MenuItem key={index + 1} value={whatsApp.id}>
                      {whatsApp.name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Typography variant="caption">
                Se não aparecer instância, crie uma nova em{" "}
                <strong>Dispositivos</strong>
              </Typography>
              <ButtonWithSpinner
                loading={isSubmitting}
                variant="contained"
                type="submit"
                disabled={!instanceChatbot.whatsappId}
                onClick={() => handleSaveChannel()}
                color="primary"
              >
                Continuar
              </ButtonWithSpinner>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
