import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";

import { makeStyles, styled } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WppIcon from "@material-ui/icons/WhatsApp";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";

import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import logoEnvia from "../../../assets/logo_enviawhats.png";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { Field, Formik, Form } from "formik";
import { i18n } from "../../../translate/i18n";
import { WhatsAppsContext } from "../../../context/WhatsApp/WhatsAppsContext";
import { socketConnection } from "../../../services/socket";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginTop: 15,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  multFieldLine: {
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function CongratsChatbot() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        Seu Bot está sendo treinado!
      </h1>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontSize: 16,
        }}
      >
        Aguarde 5 minutos para concluir o treinamento do seu chatbot...
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logoEnvia}
          alt="LogoEnvia"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      </div>
    </div>
  );
}
