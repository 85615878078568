import React, { useState, useEffect, createContext } from "react";
import { socketConnection } from "../../services/socket";
import { toast } from "react-toastify";
import useChatbots from "../../hooks/useChatbots";

const ChatbotContext = createContext();

const ChatbotContextProvider = ({ children }) => {
  

  return <ChatbotContext.Provider>{children}</ChatbotContext.Provider>;
};

export { ChatbotContext, ChatbotContextProvider };
