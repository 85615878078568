import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

export const openApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export default api;
