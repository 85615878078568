import { useRef, useState } from "react";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";

import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

const ChatbotDetailsSchema = Yup.object().shape({
  content: Yup.string()
    .required("Campo obrigatório")
    .min(1000, "Seu texto deve conter no mínimo 1000 palavras"),
});

const ChatbotDetails = ({
  bots,
  refresh,
  setUserDeleteModalOpen,
  setDeletingBot,
  setBotOnEditing,
}) => {
  const classes = useStyles();

  const textRef = useRef(null);

  const [text, setText] = useState(bots.content);
  //const [preEdit, setPreEdit] = useState(bots.content);

  const [editText, setEditText] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditText = (e) => {
    setEditText(true);
    textRef.current.focus();
  };
  const handleUpdateText = async (e) => {
    setLoading(true);
    try {
      await api.put(`/bot/${bots.id}`, {
        content: text,
      });
      setLoading(false);
      toast.success("Seu bot foi editado com sucesso!");
      setBotOnEditing(true);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }

    setEditText(false);
    refresh();
  };

  return (
    <AccordionDetails style={{ flexDirection: "column" }}>
      <textarea
        disabled={!editText}
        ref={textRef}
        onClick={(e) => e.stopPropagation()}
        value={text}
        minLength={1000}
        onChange={(e) => setText(e.target.value)}
        style={{
          width: "100%",
          height: "180px",
          position: "unset",
          zIndex: 2,
          backgroundColor: "transparent",
          padding: "5px",
          // color: "#707EAE"
        }}
      ></textarea>
      {editText ? (
        <div className={classes.wrapper}>
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <Button
              variant="contained"
              size="small"
              disabled={loading}
              color={"primary"}
              width={"100px"}
              onClick={handleUpdateText}
            >
              Salvar
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 10 }}
            color={"secondary"}
            width={"100px"}
            onClick={() => setEditText(false)}
          >
            Cancelar
          </Button>
        </div>
      ) : (
        <div className="extraAttr">
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleEditText();
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              setUserDeleteModalOpen(true);
              setDeletingBot(bots);
            }}
          >
            <Delete />
          </IconButton>
        </div>
      )}
    </AccordionDetails>
  );
};

export default ChatbotDetails;
