import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
  },
  input: {
    flexGrow: 1,
    marginRight: "1rem",
  },
  listContainer: {
    width: "100%",
    height: "100%",
    marginTop: "1rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
  },
  list: {
    marginBottom: "5px",
  },
  alertRoot: {
    width: "100%",
    marginBottom: "10px",
  },
});

const ToDoList = () => {
  const classes = useStyles();

  const [task, setTask] = useState("");
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [openAlert, setOpenAlert] = React.useState(true);

  useEffect(() => {
    const savedTasks = localStorage.getItem("tasks");
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      // Impede que o usuário crie uma tarefa sem texto
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      // Editar tarefa existente
      const newTasks = [...tasks];
      newTasks[editIndex] = {
        text: task,
        updatedAt: now,
        createdAt: newTasks[editIndex].createdAt,
      };
      setTasks(newTasks);
      setTask("");
      setEditIndex(-1);
    } else {
      // Adicionar nova tarefa
      setTasks([...tasks, { text: task, createdAt: now, updatedAt: now }]);
      setTask("");
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  return (
    <div className={classes.root + " css22"}>
      <div className={classes.alertRoot}>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            color="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            }
          >
            <ol style={{ color: "#fff" }}>
              <li>Crie tarefas, edite e exclua.</li>
            </ol>
          </Alert>
        </Collapse>
      </div>
      <div className={classes.inputContainer + " css23"}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          onChange={handleTaskChange}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          {editIndex >= 0 ? "Salvar" : "Adicionar"}
        </Button>
      </div>
      <div className={classes.listContainer + " css24"}>
        <List>
          {tasks.map((task, index) => (
            <ListItem key={index} className={classes.list + " css25"}>
              <ListItemText
                primary={task.text}
                secondary={task.updatedAt.toLocaleString()}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleEditTask(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteTask(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default ToDoList;
