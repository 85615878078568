import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useChatbots from "../../../hooks/useChatbots";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";
import api from "../../../services/api";
import { toast } from "react-toastify";
import ChatbotDetails from "../../../components/ChatbotDetails";
import { yellow } from "@material-ui/core/colors";
import toastError from "../../../errors/toastError";
import UserChatbotEditModal from "../../../components/UserChatbotEditModal";
import UserChatbotQuestionsModal from "../../../components/UserChatbotQuestionsModal";
import ConfirmationModal from "../../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listRoot: {
    width: "100%",
    //maxWidth: 360,
    //backgroundColor: theme.palette.background.paper,
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  extraAttr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    marginLeft: 20,
    marginTop: 20,
  },
  warningText: {
    color: yellow[900],
    marginLeft: 20,
  },
}));

export default function EditChatbot() {
  const classes = useStyles();

  const { fetchBots, bots, loading } = useChatbots();

  const [botOnEditing, setBotOnEditing] = useState(false);
  const [userEditModalOpen, setUserEditModalOpen] = useState(false);
  const [userQuestionsModalOpen, setUserQuestionsModalOpen] = useState(false);
  const [userDeleteModalOpen, setUserDeleteModalOpen] = useState(false);
  const [deletingBot, setDeletingBot] = useState(null);
  const [editingBot, setEditingBot] = useState(null);

  const handleCloseUserEditModal = () => {
    setUserEditModalOpen(false);
  };

  const handleCloseQuestionsModal = () => {
    setUserQuestionsModalOpen(false);
  };

  const handleDeleteBot = async (botId) => {
    try {
      await api.delete(`/bot/${botId}`);
      toast.success("Bot deletado com sucesso");
    } catch (err) {
      toastError(err);
    }
    setDeletingBot(null);
    fetchBots();
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     fetchBots();
  //   }, 500);
  //   return () => clearTimeout(delayDebounceFn);
  // }, []);

  return (
    <Paper className={classes.mainPaper}>
      <List className={classes.listRoot} key={bots.id}>
        {userQuestionsModalOpen && (
          <UserChatbotQuestionsModal
            open={userQuestionsModalOpen}
            onClose={handleCloseQuestionsModal}
            bots={editingBot}
            refresh={fetchBots}
            setBotOnEditing={setBotOnEditing}
          />
        )}
        {userEditModalOpen && (
          <UserChatbotEditModal
            open={userEditModalOpen}
            onClose={handleCloseUserEditModal}
            bots={editingBot}
            refresh={fetchBots}
          />
        )}
        <ConfirmationModal
          title="Deletar bot"
          open={userDeleteModalOpen}
          onClose={setUserDeleteModalOpen}
          onConfirm={() => handleDeleteBot(deletingBot.id)}
        >
          Deseja deletar este bot?
        </ConfirmationModal>
        {loading && <CircularProgress className={classes.extraAttr} />}
        {!loading && bots < 1 && "Você não posui bots treinados"}
        {bots &&
          bots.map(
            (bot) => (
              <>
                <ListItem key={bot.id}>
                  <ListItemText
                    id="switch-list-label-edit"
                    primary={bot.bot_name}
                  />
                  {/* {bot.fine_tune_status === "failed" && (
                    <Typography className={classes.failedText}>
                      O treinamento falhou. Edite o texto
                    </Typography>
                  )} */}
                  <>
                    {/* <Button
                  size="small"
                  variant="outlined"
                  width={"100px"}
                  onClick={() => {
                    setUserQuestionsModalOpen(true);
                    setEditingBot(bot);
                  }}
                >
                  Perguntas e Respostas
                </Button> */}
                    <Button
                      size="small"
                      variant="outlined"
                      width={"100px"}
                      onClick={() => {
                        setUserEditModalOpen(true);
                        setEditingBot(bot);
                      }}
                    >
                      Opções
                    </Button>
                  </>
                </ListItem>
                <ChatbotDetails
                  bots={bot}
                  refresh={fetchBots}
                  setDeletingBot={setDeletingBot}
                  setUserDeleteModalOpen={setUserDeleteModalOpen}
                  setBotOnEditing={setBotOnEditing}
                />
                {/* {botOnEditing ? (
                  <Typography className={classes.warningText}>
                    Seu texto foi editado. Aguarde atualizações...
                  </Typography>
                ) : (
                  <ChatbotDetails
                    bots={bot}
                    refresh={fetchBots}
                    setDeletingBot={setDeletingBot}
                    setUserDeleteModalOpen={setUserDeleteModalOpen}
                    setBotOnEditing={setBotOnEditing}
                  />
                )} */}
              </>
            )
            // bot.fine_tune_status === "succeeded" ||
            // bot.fine_tune_status === "failed" ? (
            //   <>
            //     <ListItem key={bot.id}>
            //       <ListItemText
            //         id="switch-list-label-edit"
            //         primary={bot.bot_name}
            //       />
            //       {bot.fine_tune_status === "failed" && (
            //         <Typography className={classes.failedText}>
            //           O treinamento falhou. Edite o texto
            //         </Typography>
            //       )}
            //       {!botOnEditing && (
            //         <>
            //           {/* <Button
            //             size="small"
            //             variant="outlined"
            //             width={"100px"}
            //             onClick={() => {
            //               setUserQuestionsModalOpen(true);
            //               setEditingBot(bot);
            //             }}
            //           >
            //             Perguntas e Respostas
            //           </Button> */}
            //           <Button
            //             size="small"
            //             variant="outlined"
            //             width={"100px"}
            //             onClick={() => {
            //               setUserEditModalOpen(true);
            //               setEditingBot(bot);
            //             }}
            //           >
            //             Opções
            //           </Button>
            //         </>
            //       )}
            //     </ListItem>
            //     {botOnEditing ? (
            //       <Typography className={classes.warningText}>
            //         Seu texto foi editado. Aguarde atualizações...
            //       </Typography>
            //     ) : (
            //       <ChatbotDetails
            //         bots={bot}
            //         refresh={fetchBots}
            //         setDeletingBot={setDeletingBot}
            //         setUserDeleteModalOpen={setUserDeleteModalOpen}
            //         setBotOnEditing={setBotOnEditing}
            //       />
            //     )}
            //   </>
            // ) : (
            //   <ListItem key={bot.id}>
            //     <ListItemText
            //       id="switch-list-label-active"
            //       primary={`${bot.bot_name} não pode ser editado, pois, está em treinamento`}
            //     />
            //   </ListItem>
            // )
          )}
      </List>
    </Paper>
  );
}
