import React, { useState, useEffect } from "react";
//import ReactDOM from "react-dom";
import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import axios from "axios";
import TicketMessagesDialog from "../../components/TicketMessagesDialog";
import { FaRegEye } from "react-icons/fa6";
import "./styles.css";
import fotoKanban from "../../assets/perfilKanban.png";
import { GrUpdate } from "react-icons/gr";
import { Link } from "react-router-dom";
import { Collapse, IconButton, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: "99%",
    margin: "10px",
  },
}));

// Use your own styles to override the default styles
// import "./styles.css";

const fotoK =
  '<div className="MuiListItemAvatar-root"><div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault"><svg className="MuiSvgIcon-root MuiAvatar-fallback" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg></div></div>';

function ControlledBoard() {
  const board = {
    columns: [
      {
        id: 1,
        title: "Em espera",
        cards: [
          {
            id: 1,
            title: "Carregando titulo...",
            description: "Carregando conteudo da descrição...",
          },
        ],
      },
    ],
  };

  //inicializar componente
  const [controlledBoard, setBoard] = useState(board);

  //const [atualizado, atualizar] = useState(0);

  //atribui um atendimento a uma fila
  async function updateFilas(destination, id) {
    let tokenback = localStorage.getItem("token");
    tokenback = tokenback.replace(/['"]/g, "");
    console.log(destination);
    if (tokenback) {
      const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          Authorization: `Bearer ${tokenback}`,
        },
      });
      try {
        if (destination.toColumnId != 0) {
          let data = {
            queueId: `${destination.toColumnId}`,
            status: "pending",
            userId: null,
          };
          await api.put(`/tickets/${id}`, data);
        }
        if (destination.toColumnId == 0) {
          let data = {
            queueId: null,
            status: "pending",
            userId: null,
          };
          await api.put(`/tickets/${id}`, data);
        }
      } catch (error) {
        console.error("Erro ao transferir", error);
      }
    }
  }

  async function VerificaImage(url) {
    try {
      const response = await axios.get(url);
      // Verifica se o status da resposta é 200
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // Se ocorrer um erro na requisição, considera como inválido
      return false;
    }
  }

  //obtem dados para o kanban
  async function getinfos() {
    let tokenback = localStorage.getItem("token");
    tokenback = tokenback.replace(/['"]/g, "");
    console.log(tokenback);
    if (tokenback) {
      const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          Authorization: `Bearer ${tokenback}`,
        },
      });
      try {
        const result_filas = await api.get(`/queue`);
        console.log(result_filas.data[0]);
        let board = {
          columns: [
            {
              id: 0,
              title: "Em espera",
              cards: [],
            },
          ],
        };
        //cria colunas com base nas filas
        for (let i = 0; i < result_filas.data.length; i++) {
          board.columns.push({
            id: result_filas.data[i].id,
            title: result_filas.data[i].name,
            cards: [],
          });
          // const queueIds = result_filas.data[i].id
        }
        const queueIds = result_filas?.data?.map((item) => item.id);
        const result = await api.get(
          `/tickets?queueIds=[${queueIds}]&showAll=true`
        );

        const tickets = result.data.tickets;

        console.log(tickets);
        for (let i = 0; i < tickets.length; i++) {
          for (let j = 0; j < board.columns.length; j++) {
            if (tickets[i].queueId === board.columns[j].id) {
              board.columns[j].cards.push({
                id: tickets[i].id,
                title:
                  tickets[i].contact.name + " - " + tickets[i].contact.number,
                description: tickets[i].lastMessage,
                contato: tickets[i].contact,
                origem: tickets[i].uuid,
                foto: (await VerificaImage(tickets[i].contact.profilePicUrl))
                  ? tickets[i].contact.profilePicUrl
                  : fotoKanban,
              });
              break;
            }
            if (tickets[i].queueId == null) {
              board.columns[0].cards.push({
                id: tickets[i].id,
                title:
                  tickets[i].contact.name + " - " + tickets[i].contact.number,
                description: tickets[i].lastMessage,
                contato: tickets[i].contact,
                origem: tickets[i].uuid,
                foto: (await VerificaImage(tickets[i].contact.profilePicUrl))
                  ? tickets[i].contact.profilePicUrl
                  : fotoKanban,
              });
              // console.log(await VerificaImage(tickets[i].contact.profilePicUrl))
              break;
            }
          }

          //     if (board.columns[0].id=="14545355456456" && tickets[i].status=="pending")
          //     {
          //     console.log(board.columns[i].cards)
          //     board.columns[i].cards.push ({
          //               id: tickets[i].id,
          //               title: tickets[i].contact.name +" - "+ tickets[i].contact.number,
          //               description: tickets[i].lastMessage,
          //               contato: tickets[i].contact,
          //               origem:tickets[i].uuid,
          //               foto:tickets[i].contact.profilePicUrl
          //             }

          //     )
          //     }
          //     if (true)
          //     {
          //         //console.log(board.columns[i])
          //         console.log(tickets[i])
          //         if (tickets[i].queueId==board.columns[i].id)
          // {
          //               board.columns[i].cards.push ({

          //               id: tickets[i].id,
          //               title: tickets[i].contact.name +" - "+ tickets[i].contact.number,
          //               description: tickets[i].lastMessage,
          //               contato: tickets[i].contact,
          //               origem:tickets[i].uuid,
          //               foto:tickets[i].contact.profilePicUrl
          //               }

          //               )
          // console.log(board)
          //   }  }

          //console.log(board.columns[i].id)
        }
        console.log(board);

        setBoard(board);

        // if (true)
        // {

        // }

        return result;
      } catch (error) {
        console.error("houve um erro", error);
      }
    }
  }

  const [atualizado, atualiza] = useState(0);

  useEffect(() => {
    setRotated(true);
    const updateView = () => {
      getinfos();
    };
    updateView();
    const intervalId = setInterval(updateView, 20000);

    return () => {
      clearInterval(intervalId);
      atualiza(false);
      // setRotated(false)
      console.log("limpeza");
    };
  }, [atualizado]);
  const classes = useStyles();
  const [isRotated, setRotated] = useState(false);
  const [verHistorico, SetVerHistorico] = useState(0);
  const [clickedId, setClickedId] = useState(null);
  const [openAlert, setOpenAlert] = React.useState(true);

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    console.log(destination.toColumnId);
    console.log(_card.id);
    updateFilas(destination, _card.id);
    setBoard(updatedBoard);
    //getinfos()
  }

  // Note: To enable user to create a new column all the following are needed as a minimum

  /** This is called by onColumnNew on the board when a new column has been requested and 
  the form on the ColumnAdder component has been filled in by the user. We update our state
  to get the board to render with the new data */
  const handleColumnAdded = (newBoard, newColumn) => {
    console.info("column added!");
    setBoard(newBoard);
  };

  /** This is called when a new column has been requested, its main job is 
   to give you a chance to save it and return a new id for the column */
  const handleColumnConfirmed = (newColumnName) => {
    console.info("Column id requested");
    // You will need to generate a new id for you column here - id from a database insert or similar?
    const newColumn = {
      id: "testing-but-this-should-be-unique",
      ...newColumnName,
    };
    return newColumn;
  };
  const handleEyeClick = (clickedId) => {
    console.log(clickedId);
    setClickedId(clickedId);
    SetVerHistorico(true);
  };

  return (
    <>
      <button
        className="cardPainelKanbanUpdate"
        onClick={() => atualiza(true)}
      >
        Atualizar (20s)
      </button>
      <GrUpdate
        className={`css-10 rotate-on-click ${isRotated ? "rotate" : ""}`}
        onClick={() => atualiza(true)}
      />
      <div className={classes.alertRoot}>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            color="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            }
          >
            <ol style={{ color: "#fff" }}>
              <li>
                Organize e monitore conversas de todos os setores e departamentos.
              </li>
            </ol>
          </Alert>
        </Collapse>
      </div>
      <Board
        className="containerCard"
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        allowAddColumn={false}
        onNewColumnConfirm={handleColumnConfirmed}
        onColumnNew={handleColumnAdded}
        renderCard={({ id, title, description, foto, ticket, origem }) => (
          <div className="cardKanbanContainer">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"tickets/" + origem}
            >
              <img
                style={{ height: "31px", "border-radius": "29px" }}
                alt="pl"
                src={foto}
              ></img>
              <h3 className="cardKanbanTitulo">
                {title.length > 20 ? title.slice(0, 20) + "..." : title}
              </h3>
              <p>
                {description.length > 40
                  ? description.slice(0, 40) + "..."
                  : description}
              </p>
            </Link>
            <FaRegEye className="verChat" onClick={() => handleEyeClick(id)} />
            {verHistorico && (
              <TicketMessagesDialog
                open={verHistorico}
                handleClose={() => SetVerHistorico(false)}
                ticketId={clickedId}
              />
            )}
          </div>
        )}
      >
        {controlledBoard}
      </Board>
    </>

    // <Board
    //   onCardDragEnd={handleCardMove}
    //   disableColumnDrag
    //   allowAddColumn={true}
    //   onNewColumnConfirm={handleColumnConfirmed}
    //   onColumnNew={handleColumnAdded}
    // >
    //   {controlledBoard}
    // </Board>
  );
}

export default ControlledBoard;

// function UncontrolledBoard() {
//   return (
//     <Board
//       allowRemoveLane
//       allowRenameColumn
//       allowRemoveCard
//       onLaneRemove={console.log}
//       onCardRemove={console.log}
//       onLaneRename={console.log}
//       initialBoard={board}
//       allowAddCard={{ on: "top" }}
//       onNewCardConfirm={(draftCard) => ({
//         id: new Date().getTime(),
//         ...draftCard
//       })}
//       onCardNew={console.log}
//     />
//   );
// }
