import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { socketConnection } from "../../services/socket";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import UserChatbotModal from "../../components/UserChatbotModal";
import UserChatbotEditModal from "../../components/UserChatbotEditModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import ChatbotDetails from "../../components/ChatbotDetails";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  attr: {
    display: "flex",
    width: "100%",
    gap: 15,
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-start",
  },
  attrIcon: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
  attrEnd: {
    display: "flex",
    gap: 15,
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-end",
  },
  extraAttr: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  failedText: {
    color: "red",
  },
}));

const UserChatbotChatbot = () => {
  const classes = useStyles();

  const [bots, setBots] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userEditModalOpen, setUserEditModalOpen] = useState(false);
  const [userDeleteModalOpen, setUserDeleteModalOpen] = useState(false);
  const [deletingBot, setDeletingBot] = useState(null);
  const [editingBot, setEditingBot] = useState(null);
  const [openedCollapse, setOpenedCollapse] = useState({});
  //const [enabled, setEnabled] = useState(false);
  //const [chatbots, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setUserModalOpen(false);
  };

  const handleCloseUserEditModal = () => {
    setUserEditModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleCollapse = () => {
    setOpenedCollapse({
      ...openedCollapse,
    });
  };

  const toggleBotStatus = async (botId, currentStatus) => {
    await api.put(`/bot/${botId}`, {
      status: !currentStatus,
    });
    toast.success("Status alterado com sucesso!");

    fetchBots();
  };

  const handleDeleteBot = async (botId) => {
    try {
      await api.delete(`/bot/${botId}`);
      toast.success("Bot deletado com sucesso");
    } catch (err) {
      toastError(err);
    }
    setDeletingBot(null);
    fetchBots();
  };

  // useEffect(() => {
  //   textRef.current.focus();
  // }, [editText])

  const fetchBots = useCallback(async () => {
    try {
      const { data } = await api.get("/bot", {});
      console.log(data);
      setBots(data);

      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchBots();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const socket = socketConnection();

    socket.on("connect", () => {
      socket.emit("generate-bot", userId);
    });

    socket.on("gpt-status", (data) => {
      console.log("aqui", data);
    });
  }, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title="Deletar bot"
        open={userDeleteModalOpen}
        onClose={setUserDeleteModalOpen}
        onConfirm={() => handleDeleteBot(deletingBot.id)}
      >
        Deseja deletar este bot?
      </ConfirmationModal>
      {userModalOpen && (
        <UserChatbotModal
          open={userModalOpen}
          onClose={handleCloseUserModal}
          bots={bots}
          refresh={fetchBots}
        />
      )}
      {userEditModalOpen && (
        <UserChatbotEditModal
          open={userEditModalOpen}
          onClose={handleCloseUserEditModal}
          bots={editingBot}
          refresh={fetchBots}
        />
      )}

      <MainHeader>
        <Title>{i18n.t("usersChatbot.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenUserModal}
          >
            {i18n.t("usersChatbot.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <>
          {!bots
            ? "Não possui bots"
            : bots.map((bot) =>
                bot.fine_tune_status === "succeeded" ||
                bot.fine_tune_status === "failed" ? (
                  <Accordion onClick={handleCollapse} key={bot.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ flexDirection: "row" }}
                    >
                      <div className={classes.attr}>
                        <div className={classes.attrIcon}>
                          {bot.status ? (
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                borderRadius: "50%",
                                backgroundColor: "#2ecc71",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                borderRadius: "50%",
                                backgroundColor: "#f39c12",
                              }}
                            />
                          )}
                          <Typography>{bot.bot_name}</Typography>
                        </div>
                        <Button
                          size="small"
                          variant="outlined"
                          width={"100px"}
                          onClick={() => {
                            setUserEditModalOpen(true);
                            setEditingBot(bot);
                          }}
                        >
                          Opções
                        </Button>

                        {bot.fine_tune_status === "failed" && (
                          <Typography className={classes.failedText}>
                            O treinamento falhou. Edite o texto
                          </Typography>
                        )}
                      </div>
                      <div className={classes.attrEnd}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={bot.status}
                              onChange={() => {
                                toggleBotStatus(bot.id, bot.status);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={bot.status ? "Ligado" : "Desligado"}
                          labelPlacement="start"
                        />
                      </div>
                    </AccordionSummary>
                    <ChatbotDetails
                      bots={bot}
                      refresh={fetchBots}
                      setDeletingBot={setDeletingBot}
                      setUserDeleteModalOpen={setUserDeleteModalOpen}
                    />
                  </Accordion>
                ) : (
                  <Accordion disabled key={bot.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>
                        {bot.bot_name} está em treinamento
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                )
              )}
        </>
      </Paper>
    </MainContainer>
  );
};

export default UserChatbotChatbot;
