import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import {
  makeStyles,
  Button,
  Paper,
  Collapse,
  IconButton,
} from "@material-ui/core";

import QuickMessagesTable from "../../components/QuickMessagesTable";
import QuickMessageDialog from "../../components/QuickMessageDialog";
import ConfirmationModal from "../../components/ConfirmationModal";

import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";

import useQuickMessages from "../../hooks/useQuickMessages";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  alertRoot: {
    width: "100%",
    marginBottom: "10px",
  },
}));

function QuickMessages(props) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [messageSelected, setMessageSelected] = useState({});
  const [showOnDeleteDialog, setShowOnDeleteDialog] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(true);

  const {
    list: listMessages,
    save: saveMessage,
    update: updateMessage,
    deleteRecord: deleteMessage,
  } = useQuickMessages();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      await loadingQuickMessages();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingQuickMessages = async () => {
    setLoading(true);
    try {
      const companyId = localStorage.getItem("companyId");
      const messages = await listMessages({ companyId, userId: user.id });
      setMessages(messages);
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const handleOpenToAdd = () => {
    setModalOpen(true);
  };

  const handleOpenToEdit = (message) => {
    setMessageSelected(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessageSelected({ id: null, message: "", shortcode: "" });
  };

  const handleSave = async (message) => {
    handleCloseModal();
    try {
      await saveMessage(message);
      await loadingQuickMessages();
      toast.success("Messagem adicionada com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleEdit = async (message) => {
    handleCloseModal();
    try {
      await updateMessage(message);
      await loadingQuickMessages();
      toast.success("Messagem atualizada com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleDelete = async (message) => {
    handleCloseModal();
    try {
      await deleteMessage(message.id);
      await loadingQuickMessages();
      toast.success("Messagem excluída com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("quickMessages.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button variant="contained" color="primary" onClick={handleOpenToAdd}>
            {i18n.t("quickMessages.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <div className={classes.alertRoot + ' css76'}>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            color="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            }
          >
            <ol style={{ color: "#fff" }}>
              <li>As respostas rápidas otimizam o processo de atendimento ao cliente para perguntas frequentes ou consultas comuns utilizando a tecla "/ e o nome da Palavra-Chave" em seus atendimentos, você poderá selecionar a melhor resposta.</li>
            </ol>
          </Alert>
        </Collapse>
      </div>
      <Paper className={classes.mainPaper + ' css77'} variant="outlined">
        <QuickMessagesTable
          readOnly={false}
          messages={messages}
          showLoading={loading}
          editMessage={handleOpenToEdit}
          deleteMessage={(message) => {
            setMessageSelected(message);
            setShowOnDeleteDialog(true);
          }}
        />
      </Paper>
      <QuickMessageDialog
        messageSelected={messageSelected}
        modalOpen={modalOpen}
        onClose={handleCloseModal}
        editMessage={handleEdit}
        saveMessage={handleSave}
      />
      <ConfirmationModal
        title="Excluir Registro"
        open={showOnDeleteDialog}
        onClose={setShowOnDeleteDialog}
        onConfirm={async () => {
          await handleDelete(messageSelected);
        }}
      >
        Deseja realmente excluir este registro?
      </ConfirmationModal>
    </MainContainer>
  );
}

export default QuickMessages;
