/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import MainContainer from "../../components/MainContainer";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { CircularProgress, Collapse, IconButton } from "@material-ui/core";
import "./chatbot.css";
import logoEnviawhats from "../../assets/logo_enviawhats.png";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  imgAnimated: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "10vw",
    height: "10vh",
  },
  alertRoot: {
    width: "100%",
    marginBottom: "10px",
  },
}));

const Chatbot = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [loading, setLoading] = useState(false);
 const [urlTypebot, seturlTypebot] = useState(false);
  const email = user.email;



async function Typebot() {
    const company = localStorage.getItem("companyId");
    const email = localStorage.getItem("email");

    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/typebot`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await api
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.url));

        api
          .get(`${process.env.REACT_APP_BACKEND_URL}/companies/${company}`)
          .then((response) => {
            console.log(response.data.url);
            console.log("peguei o link");
            window.open(response.data.typeboturl, "_blank");
          })
          .catch((error) => {
            window.open(response.data.url, "_blank");
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }













 
  const fetchTypebot = useCallback(async () => {
    setLoading(true);
    try {
    await Typebot()
    console.log(urlTypebot)
    



      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTypebot();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  // useEffect(() => {
  //   const companyId = localStorage.getItem("companyId");
  //   const socket = socketConnection({ companyId });

  //   socket.on("connect", () => {
  //     socket.emit("typebot", email);
  //   });

  //   socket.on(`typebotUrl`, (data) => {
  //     console.log('aqui', data);

  //     window.open(`${data.url}`, '_blank');
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };

  // }, [email]);

  return (
    <MainContainer>
      <div className={classes.alertRoot + ' css78'}>
        <Collapse in={openAlert}>
          <Alert
            icon={false}
            color="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <Close fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            }
          >
            <ol style={{ color: "#fff" }}>
              <li>
                Você será redirecionado para a tela de criação de Fluxograma
              </li>
            </ol>
          </Alert>
        </Collapse>
      </div>
      <Paper className={classes.mainPaper + ' css75'} variant="outlined">
        {/* {
          loading ? (
            <div className={classes.content}>
            <img src={logoEnviawhats} className="rotate-scale-up" alt="Logo EnviaWhats"/>
            <Typography className="text-blur-out">O seu Fluxograma está sendo gerado...</Typography>
            </div>
          ) : (
            <div className={classes.content}>
            <Typography>Acesse o fluxograma na outra aba</Typography>
            </div>
          )
        } */}

        <div className={classes.content + ' css76'}>
          <img
            src={logoEnviawhats}
            className="rotate-scale-up"
            alt="Logo EnviaWhats"
          />
          <Typography className="text-blur-out">Construtor de Bots</Typography>
        </div>

        <div className={classes.content + ' css77'}>
          <Typography>
            O modulo de fluxograma estará disponivel em breve.
          </Typography>
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Chatbot;
