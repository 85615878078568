import React, { useState } from "react";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField, Paper } from "@material-ui/core";

import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { Field, Formik, Form } from "formik";
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";
import { toast } from "react-toastify";
import toastError from "../../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  svg: {
    marginTop: 15,
  },
  flexForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  flexFormRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  flexButtonRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
}));

const ChatbotQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .required("Campo obrigatório")
    .min(6, "Pergunta muito curta"),
  answer: Yup.string()
    .required("Campo obrigatório")
    .min(6, "Resposta muito curta"),
});

export default function QuestionsChatbot({ handleNext }) {
  const classes = useStyles();

  const initialState = [
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
    {
      question: "",
      answer: "",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [contentChatbot, setContentChatbot] = useState(initialState);
  const chatbotName = localStorage.getItem("chatbotName");
  const instanceName = localStorage.getItem("instanceName");
  const contentName = localStorage.getItem("contentName");

  const handleSaveFinish = async (values) => {
    // console.log({
    //   whatsappId: instanceName,
    //   name: chatbotName,
    //   content: contentName,
    //   status: false,
    //   history: true,
    //   conversations: values,
    // });
    try {
      await api.post("/bot", {
        whatsappId: instanceName,
        name: chatbotName,
        content: contentName,
        status: false,
        history: true,
        conversations: values,
      });

      handleNext();

      toast.warning("Seu bot está em analise! Aguarde uns instantes...");
    } catch (err) {
      toastError(err);
    }
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...contentChatbot];
    onChangeValue[index][name] = value;
    setContentChatbot(onChangeValue);
  };

  const handleAddInput = () => {
    setContentChatbot([
      ...contentChatbot,
      {
        question: "",
        answer: "",
      },
    ]);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...contentChatbot];
    newArray.splice(index, 1);
    setContentChatbot(newArray);
  };

  return (
    <Paper className={classes.mainPaper}>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        Perguntas e respostas comuns
      </h1>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontSize: 16,
        }}
      >
        Escreva no minimo 10 perguntas e respostas para otimizar o bot em seu
        atendimento
      </p>

      <Formik
        initialValues={contentChatbot}
        enableReinitialize={true}
        validationSchema={ChatbotQuestionSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveFinish(values);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <div className={classes.flexForm}>
              {contentChatbot.map((item, index) => (
                <div className={classes.flexFormRow} key={index}>
                  <Field
                    as={TextField}
                    name="question"
                    value={item.question}
                    placeholder="Pergunta"
                    variant="outlined"
                    margin="dense"
                    required
                    onChange={(event) => handleChange(event, index)}
                  />
                  <Field
                    as={TextField}
                    name="answer"
                    value={item.answer}
                    placeholder="Resposta"
                    variant="outlined"
                    margin="dense"
                    required
                    onChange={(event) => handleChange(event, index)}
                  />
                  {contentChatbot.length > 10 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteInput(index)}
                    >
                      Deletar
                    </Button>
                  )}
                </div>
              ))}

              <div className={classes.flexButtonRow}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleAddInput()}
                >
                  Adicionar
                </Button>

                <ButtonWithSpinner
                  //loading={isSubmitting}
                  variant="contained"
                  type="submit"
                  //onClick={() => handleNextStep()}
                  color="primary"
                >
                  Salvar
                </ButtonWithSpinner>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
