import React, { useState, useEffect, useContext } from "react";
import qs from 'query-string'

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";

import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import logo from "../../assets/logologin.png";
import authImage from "../../assets/auth.png"
import { AuthContext } from "../../context/Auth/AuthContext";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		justifyContent: "center",
		margin: 0, 
		padding: 0, 
		height: "100vh", 
		width: "100%", 
		maxWidth: "none",
		overflowY: "hidden"
	},
	header: {
		margin: 0,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start"
	},
	title: {
		margin: 0,
		fontSize: "36px",
		marginBottom: "10px",
		color: "#1B254B",
	},
	subtitle: {
		margin: 0,
		marginBottom: "20px",
		color: "#444444",
	},
	paper: {
		margin: 0,
		width: "55%",
		padding: "0 15% 0 5%",
		marginTop: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: "white",
		height: "50px"
	},
	redirect: {
		display: "flex",
		alignItems: "center",
		gap: "5px",
		fontSize: "14px",
		cursor: "pointer",
		textDecoration: "none",
		color: theme.palette.primary.main
	},
	image: {
		width: "45%",
		height: "100%", 
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curta")
		.max(50, "Muito longa")
		.required("Obrigatório"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();

	const { handleSignupLogin } = useContext(AuthContext);

	let companyId = null

	const params = qs.parse(window.location.search)
	if (params.companyId !== undefined) {
		companyId = params.companyId
	}

	const initialState = { name: "", email: "", password: "", planId: "", };

	//console.log(initialState);
	const [user] = useState(initialState);
	const dueDate = moment().add(14, "day").format();
	const handleSignUp = async values => {
		Object.assign(values, { recurrence: "MENSAL" });
		Object.assign(values, { dueDate: dueDate });
		Object.assign(values, { status: "t" });
		Object.assign(values, { campaignsEnabled: true });
		try {
			await openApi.post("/companies/cadastro", values);
			toast.success(i18n.t("signup.toasts.success"));
			localStorage.setItem("email",values.email)
			localStorage.setItem("PlanId",values.planId)
			console.log(values.planId)
			handleSignupLogin(values)
		} catch (err) {
			console.log(err);
			toastError(err);
		}
	};

	const [plans, setPlans] = useState([]);
	const { list: listPlans } = usePlans();

	useEffect(() => {
		async function fetchData() {
			const list = await listPlans();
			setPlans(list);
		}
		fetchData();
	}, []);

	const [windowSize, setWindowSize] = useState(getWindowSize());

	function getWindowSize() {
		const {innerWidth, innerHeight} = window;
		return {innerWidth, innerHeight};
	}

	useEffect(() => {
		function handleWindowResize() {
		  setWindowSize(getWindowSize());
		}
	
		window.addEventListener('resize', handleWindowResize);
	
		return () => {
		  window.removeEventListener('resize', handleWindowResize);
		};
	  }, []);
	

	return (
		<Container component="main" className={classes.container + ' css121'}>
			<CssBaseline />
			<div 
			className={classes.paper + ' css122'}
			style={windowSize.innerWidth <= 750 ? {width: "100%", maxWidth: "90%", padding: 0, } : null}
			>
				
				<Box className={classes.header + ' css123'}>
					<a href="https://painel.enviawhats.com">
						<img
							src="https://enviawhats.com/uploads/23/10/1696478434XdvsU33AeqbcAAhgqtgY.png"
							alt="Logo"
							className="imagem-css"
							style={{ width: '270px' }}
						/>
					</a>
					<h1 className={classes.title + ' css124'}>Cadastre-se</h1>
					<p className={classes.subtitle + ' css125'}>Insira seus dados básicos para criar uma conta</p>
				</Box>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form className={classes.form + ' css126'}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="name"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										fullWidth
										id="name"
										label="Nome da Empresa"
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="email"
										label={i18n.t("signup.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										autoComplete="email"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										label={i18n.t("signup.form.password")}
										type="password"
										id="password"
										autoComplete="current-password"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="plan-selection">Plano</InputLabel>
									<Field
										as={Select}
										variant="outlined"
										fullWidth
										id="plan-selection"
										label="Plano"
										name="planId"
										required
									>
										{plans.map((plan, key) => (
											<MenuItem key={key} value={plan.id}>
												{plan.name} - Atendentes: {plan.users} - WhatsApp: {plan.connections} - Filas: {plan.queues} - R$ {plan.value}
											</MenuItem>
										))}
									</Field>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit + ' css127'}
							>
								{i18n.t("signup.buttons.submit")}
							</Button>
							<Grid container>
								<Grid item>
									{/* {i18n.t("login.buttons.register")} */}
									<Link
										href="#"
										variant="body2"
										component={RouterLink}
										to="/login"
									>
										{i18n.t("signup.buttons.second")}
									</Link>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>

				<Box style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "60px"}}>
					<a href="https://enviawhats.com" className={classes.redirect + ' css128'}><ArrowBack style={{width: "15px", height: "15px"}}/> Voltar para Home</a>
				</Box>
				
			</div>
			{windowSize.innerWidth > 750 ? (
				<div className={classes.image + ' css130'}>
					<img
						src="https://enviawhats.com/assets/img/5.png"
						style={{
							margin: "50px auto 0 auto", // Margem de 50px no topo, e alinhamento horizontal ao centro
							display: "block", // Tornar a imagem um elemento de bloco
						}}
						alt="Descrição da Imagem"
					/>
				</div>
			) : null}
			{/* <Box mt={5}><Copyright /></Box> */}
		</Container>
	);
};

export default SignUp;
