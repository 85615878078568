import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logologin.png";
import authImage from "../../assets/auth.png"
import { ArrowBack, ArrowForward } from "@material-ui/icons";


// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		justifyContent: "center",
		margin: 0, 
		padding: 0, 
		height: "100vh", 
		width: "100%", 
		maxWidth: "none",
		overflowY: "hidden"
	},
	header: {
		margin: 0,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start"
	},
	title: {
		margin: 0,
		fontSize: "36px",
		marginBottom: "10px",
		color: "#1B254B",
	},
	subtitle: {
		margin: 0,
		marginBottom: "20px",
		color: "#444444",
	},
	paper: {
		margin: 0,
		width: "55%",
		padding: "0 15% 0 5%",
		marginTop: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: "white",
		height: "50px"
	},
	redirect: {
		display: "flex",
		alignItems: "center",
		gap: "5px",
		fontSize: "14px",
		cursor: "pointer",
		textDecoration: "none",
		color: theme.palette.primary.main
	},
	image: {
		width: "45%",
		height: "100%", 
	}
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		localStorage.setItem("email",user.email)
		e.preventDefault();
		handleLogin(user);
	};

	const [windowSize, setWindowSize] = useState(getWindowSize());

	function getWindowSize() {
		const {innerWidth, innerHeight} = window;
		return {innerWidth, innerHeight};
	}

	useEffect(() => {
		function handleWindowResize() {
		  setWindowSize(getWindowSize());
		}
	
		window.addEventListener('resize', handleWindowResize);
	
		return () => {
		  window.removeEventListener('resize', handleWindowResize);
		};
	  }, []);

	return (
		<Container component="main" className={classes.container + ' css121'}>
			<CssBaseline />
			<div 
			className={classes.paper + ' css122'} 
			style={windowSize.innerWidth <= 750 ? {width: "100%", maxWidth: "90%", padding: 0, } : null}>

				<Box className={classes.header + ' css123'}>
					<a href="https://painel.enviawhats.com">
						<img
							src="https://enviawhats.com/uploads/23/10/1696478434XdvsU33AeqbcAAhgqtgY.png"
							alt="Logo"
							className="imagem-css"
							style={{ width: '270px' }}
						/>
					</a>
					<h1 className={classes.title + ' css124'}>Login</h1>
					<p className={classes.subtitle + ' css125'}>Insira seu email e senha para continuar!</p>
				</Box>
				<form className={classes.form + ' css126'} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
						
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						style={{color: "white"}}
						className={classes.submit + ' css127'}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					<Grid container>
						<Grid item>
						{/* {i18n.t("login.buttons.register")} */}
							<Link
								href="#"
								variant="body2"
								component={RouterLink}
								to="/signup"
							>
								{i18n.t("login.buttons.register")}
							</Link>
						</Grid>
					</Grid>
				</form>
				
				<Box style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "60px"}}>
					<a href="https://enviawhats.com" className={classes.redirect + ' css128'}><ArrowBack style={{width: "15px", height: "15px"}}/> Voltar para Home</a>
				</Box>
				
			</div>
			
			{windowSize.innerWidth > 750 ? (
				<div className={classes.image + ' css130'}>
					<img
						src="https://enviawhats.com/assets/img/5.png"
						style={{
							margin: "50px auto 0 auto", // Margem de 50px no topo, e alinhamento horizontal ao centro
							display: "block", // Tornar a imagem um elemento de bloco
						}}
						alt="Descrição da Imagem"
					/>
				</div>
			) : null}
			

		</Container>
	);
};

export default Login;
